import { AutoComplete, Input, Row, Skeleton } from "antd";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { browsePatients } from "../../api";
import { useWorkSpace } from "../../contexts";
import { Paths } from "../../router";

interface Props {}

export const PatientsSearch: React.FC<Props> = () => {
  const { currentWorkspace } = useWorkSpace();
  const [search, setSearch] = useState<string>("");
  const [searchApi, setSearchApi] = useState<string>("");
  const { t } = useTranslation();

  const debounceSearch = useRef(debounce(setSearchApi, 300));

  const { data, isLoading } = useQuery(
    ["patients-search", searchApi, currentWorkspace?.workspace_id],
    () =>
      browsePatients(currentWorkspace?.workspace_id!, {
        search: search.trim(),
      }),
    {
      enabled: Boolean(currentWorkspace?.workspace_id),
    }
  );

  useEffect(() => {
    const current = debounceSearch.current;
    current(search);
    return () => current.cancel();
  });

  const navigate = useNavigate();

  const options = data?.data?.map((patient) => ({
    label: `${patient.first_name} ${patient.last_name}`,
    value: patient.patient_id,
  }));

  return (
    <StyledPatientsSearch>
      <AutoComplete
        style={{ width: "100%" }}
        options={options}
        onSelect={(patient_id) => navigate(`${Paths.Patients}/${patient_id}`)}
        searchValue={search}
        onChange={(value) => setSearch(value)}
        notFoundContent={
          isLoading ? (
            <Row>
              <Skeleton
                active
                paragraph={{ rows: 0 }}
                title={{ style: { marginTop: 16, width: "100%" } }}
              />
            </Row>
          ) : (
            t("no_result")
          )
        }
      >
        <Input.Search
          size="large"
          placeholder={t("search_placeholder") as string}
          enterButton
        />
      </AutoComplete>
    </StyledPatientsSearch>
  );
};

const StyledPatientsSearch = styled.div`
  h3 {
    margin: 0px;
  }
`;
