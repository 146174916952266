import { Layout, Spin } from "antd";
import styled from "styled-components";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useScreen } from "../hooks";

export const LoadingWorkspace: React.FC = () => {
  const { screenHeight } = useScreen();
  const { t } = useTranslation();
  return (
    <StyledLayout $height={`${screenHeight}px`}>
      <Layout.Content>
        <div>
          <Typography.Title level={4}>
            {t("loading_workspaces")}
          </Typography.Title>
          <Spin size="large" />
        </div>
      </Layout.Content>
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)<{ $height: string }>`
  .ant-layout-content {
    height: ${({ $height }) => $height};
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      text-align: center;
      h4 {
        margin-bottom: 24px;
      }
    }
  }
`;
