import { t } from "i18next";

export type AuthErrorCode =
  | "auth/user-not-found"
  | "auth/wrong-password"
  | "auth/network-request-failed";

export const generateError = (error: AuthErrorCode) => {
  if (error === "auth/network-request-failed") {
    return t("network_error");
  }
  return t("auth_login_error");
};
