import { Typography } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import styled from "styled-components";
import { Patient } from "../../model";
import { getDateFormat, getLocale, getTimeFormat } from "../../utils";

type Props = Partial<Patient> & {
  onlyUpdatedAt?: boolean;
  onlyCreatedAt?: boolean;
};

export const TimestampDisplay: React.FC<Props> = ({
  created_at,
  updated_at,
  onlyUpdatedAt,
  onlyCreatedAt,
}) => {
  const locale = getLocale();
  const date_format = getDateFormat(locale);
  const time_format = getTimeFormat(locale);
  const display_date_updated = dayjs(updated_at)
    .locale(locale)
    .format(date_format);
  const display_date_created = dayjs(created_at)
    .locale(locale)
    .format(date_format);
  const display_time_updated = dayjs(updated_at)
    .locale(locale)
    .format(time_format);
  const display_time_created = dayjs(created_at)
    .locale(locale)
    .format(time_format);
  return (
    <StyledTimestampDisplay>
      {!Boolean(onlyCreatedAt) && (
        <Typography.Paragraph italic type="secondary">
          {t("last_edited_on")}{" "}
          {updated_at && `${display_date_updated}, ${display_time_updated}`}
        </Typography.Paragraph>
      )}
      {!Boolean(onlyUpdatedAt) && (
        <Typography.Paragraph italic type="secondary">
          {t("added_on")}{" "}
          {created_at && `${display_date_created}, ${display_time_created}`}
        </Typography.Paragraph>
      )}
    </StyledTimestampDisplay>
  );
};

const StyledTimestampDisplay = styled.div`
  .ant-typography {
    margin: 0px;
  }
  font-size: 12px;
`;
