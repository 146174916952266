export const fr = {
  translation: {
    language: "Langue",
    time: "Horaires",

    today: "Aujourd'hui",

    years: "ANS",

    password: "Mot de passe",
    remember_me: "Se rappeler de moi",

    not_available_on_mobile:
      "Cette page n'est pas encore disponible sur mobile",

    files: "Fichiers",
    file: "Fichier",

    login: "Connexion",
    upload: "Télécharger",
    save: "Enregistrer",
    create: "Créer",
    cancel: "Annuler",
    edit: "Modifier",
    delete: "Supprimer",
    close: "Fermer",
    see_more: "Voir plus",
    add_new: "Ajouter",
    add_title: "Ajouter un titre",

    switch_to_dark_theme: "Passer au thème sombre",
    switch_to_light_theme: "Passer au thème clair",

    added_on: "Ajouté le",
    last_edited_on: "Dernière modification le",
    date_format: "YYYY-MM-DD",

    search_placeholder: "Recherche..",
    no_result: "Aucun résultat",

    no: "Non",
    yes: "Oui",

    from: "De",
    to: "à",

    dashboard: "Dashboard",
    profile: "Profile",
    settings: "Paramètres",
    calendar: "Calendrier",

    logout: "Déconnexion",
    back: "Retour",

    good_day: "Bonjour",
    have_a_nice_day: "Passez un merveilleux",
    sign_in: "S'identifier",

    patients: "Patients",
    patient: "Patient",
    new_patient: "Nouveau patient",

    name: "Nom",
    first_name: "Prénom",
    last_name: "Nom",
    gender: "Sexe",
    birthdate: "Date de naissance",
    phone: "Téléphone",
    phone_code: "Indicatif",
    address: "Adresse",
    male: "Homme",
    female: "Femme",
    email: "E-mail",

    email_error:
      "Il doit s'agir d'un format d'e-mail, par exemple : e-mail@domaine.com",

    date_format_placeholder: "AAAA-MM-JJ",

    delete_modal_title: "Supprimer {{entity}}",
    are_you_sure_delete: "Voulez-vous vraiment supprimer {{name}}?",
    delete_success_message: "Supprimé avec succés",
    edit_success_message: "Modifié avec succés",
    create_success_message: "Créé avec succés",

    loading_workspaces: "Chargement du cabinet en cours..",
    loading_calendar: "Chargement du calendrier en cours..",

    patient_collection_observations: "Observations",
    patient_collection_biology: "Biologie",
    patient_collection_endoscopy: "Endoscopie",
    patient_collection_proctology: "Proctologie",

    new_note: "Nouvelle note..",
    this_note: "cette note",
    note: "Note",

    file_dragger_placeholder:
      "Cliquez ou faites glisser le fichier dans cette zone pour le télécharger",
    file_dragger_rules: "Seuls les images et les fichiers PDF sont acceptés",

    go_back: "Retour",
    go_dashboard: "Retour au dashboard",

    error_404_message: "Désolé, la page que vous avez visitée n'existe pas.",
    error_500_message:
      "Désolé, une erreur est survenue. Veuillez ressayer plus tard",
    network_error: "Erreur réseau, veuillez réessayer plus tard.",
    auth_login_error:
      "Veuillez vérifier votre E-mail et votre mot de passe et réessayer.",

    password_input_error:
      "Le champ mot de passe ne peut pas être vide. Veuillez entrer un mot de passe pour continuer.",
    email_input_error: "Veuillez saisir une adresse mail",

    choose_file_name: "Choisir le nom de votre fichier",

    consultation: "Consultation",
    consultations: "Consultations",
    next_consultations: "Aujourd'hui",
    new_consultation: "Nouvelle consultation",
    cancel_consultation: "Annuler la consultation",
    are_you_sure_cancel_consultation:
      "Voulez vous vraiment annuler cette consultation?",
    link_patient: "Lier à un patient existant?",
    no_consultation_today: "Aucune consultation n'est prévue pour aujourd'hui",
    no_consultation_planned: "Aucune consultation planifiée",

    end_time_error:
      "L'heure du début ne peut pas être supérieure a l'heure de la fin",
    patient_name_error:
      "Assurez vous d'avoir entrer un nom et un prénom pour le nouveau patient et ressayez.",

    new_patient_widget_placeholder: "Créés ce mois-ci",
    new_patient_widget_title: "Nouveaux patients",
    total_patients_placeholder: "Vouz avez",
    total_patients: "TOTAL PATIENTS",

    weekly_consultations_widget_placeholder: "Planifiées pour cette semaine",
  },
};
