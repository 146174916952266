import { Button } from "antd";
import styled from "styled-components";
import { useMobileDrawer } from "../contexts";
import { MenuFoldOutlined } from "@ant-design/icons";

interface Props {}

export const MobileDrawerOpener: React.FC<Props> = () => {
  const { openMobileDrawer } = useMobileDrawer();
  return (
    <StyledMobileDrawerOpener>
      <Button
        onClick={openMobileDrawer}
        size="large"
        icon={<MenuFoldOutlined />}
      />
    </StyledMobileDrawerOpener>
  );
};

const StyledMobileDrawerOpener = styled.div``;
