import { Input } from "antd";
import { t } from "i18next";
import styled from "styled-components";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const AddNoteForm: React.FC<Props> = ({ onChange, value }) => {
  return (
    <StyledAddNoteForm>
      <Input.TextArea
        size="large"
        autoFocus
        placeholder={t("new_note")!}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </StyledAddNoteForm>
  );
};

const StyledAddNoteForm = styled.div`
  textarea {
    height: 250px;
    padding: 16px !important;
    margin-bottom: 8px;
  }
`;
