import dayjs from "dayjs";
import { Event } from "../model";

export const newEvent = (): Event => ({
  title: "",
  start_date: dayjs().format("HH:mm"),
  end_date: dayjs().format("HH:mm"),
  type: "consultation",
  patient_id: "-1",
  created_by: "",
  event_id: "-1",
});

export const isValidEvent = (event: Event): boolean =>
  Boolean(event.start_date) &&
  Boolean(event.end_date) &&
  Boolean(event.title.trim());
