import {
  AutoComplete,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { usePatientFormContext } from "../../contexts";
import { phone_indicatives } from "../../utils";

export const PatientForm: React.FC = () => {
  const { formValues, updateValues } = usePatientFormContext();
  const { t } = useTranslation();
  const [phoneIndicative, setPhoneIndicative] = useState(
    formValues?.phone_number?.code || ""
  );

  const phoneCodesOptions = phone_indicatives.map((code) => ({
    label: code.code,
    value: code.code,
    key: code.flag,
  }));

  const handlePhoneCodeUpdate = (newCode: string) => {
    updateValues({
      ...formValues,
      phone_number: { ...formValues?.phone_number, code: newCode },
    });
  };

  const handleFormValueChanges = (_: any, newValues: any) => {
    const formattedNewValues = {
      ...newValues,
      phone_number: newValues?.phone_number
        ? { code: phoneIndicative, number: newValues?.phone_number }
        : { code: "", number: "" },
      birthdate: newValues.birthdate
        ? newValues.birthdate.format("YYYY-MM-DD")
        : "",
      first_name: newValues.first_name.trim(),
      last_name: newValues.last_name.trim(),
    };
    updateValues({ ...formValues, ...formattedNewValues });
  };

  return (
    <StyledPatientForm>
      <Form
        onValuesChange={handleFormValueChanges}
        initialValues={
          formValues.birthdate
            ? {
                ...formValues,
                birthdate: dayjs(formValues.birthdate),
                phone_number: formValues?.phone_number?.number,
              }
            : { ...formValues, phone_number: formValues?.phone_number?.number }
        }
        layout="horizontal"
        size="middle"
      >
        <Form.Item name="last_name" required label={t("last_name")}>
          <Input size="large" placeholder={t("last_name")!} />
        </Form.Item>
        <Form.Item name="first_name" required label={t("first_name")}>
          <Input size="large" placeholder={t("first_name")!} />
        </Form.Item>
        <Form.Item valuePropName="value" name="gender" label={t("gender")}>
          <Radio.Group size="large">
            <Radio value="M"> {t("male")} </Radio>
            <Radio value="F"> {t("female")} </Radio>
          </Radio.Group>
        </Form.Item>
        <StyledDateRow gutter={[16, 16]} wrap={false} align="middle">
          <Col>
            <Form.Item
              valuePropName="value"
              name="birthdate"
              label={t("birthdate")}
            >
              <DatePicker
                size="large"
                name="birthdate"
                style={{ width: 140 }}
                placeholder="1980-12-31"
              />
            </Form.Item>
          </Col>
          <Col flex="150px">
            <Typography.Text
              style={{
                fontSize: 12,
              }}
              type="secondary"
            >
              ({t("date_format_placeholder")})
            </Typography.Text>
          </Col>
        </StyledDateRow>
        <Form.Item name="phone_number" label={t("phone")}>
          <Input
            size="large"
            addonBefore={
              <AutoComplete
                placeholder={t("phone_code")}
                style={{ width: 80 }}
                onChange={(v) => {
                  setPhoneIndicative(v);
                  handlePhoneCodeUpdate(v);
                }}
                value={phoneIndicative}
                options={phoneCodesOptions}
              />
            }
            placeholder="71 275 606"
          />
        </Form.Item>
        <Form.Item
          rules={[{ type: "email", message: t("email_error") as string }]}
          name="email"
          label={t("email")}
        >
          <Input size="large" placeholder="example@email.com" />
        </Form.Item>
        <Form.Item name="address" label={t("address")}>
          <Input.TextArea
            size="large"
            placeholder="11 Rue Farhat Hached, Le Kram, Tunis, 2015"
            rows={4}
          />
        </Form.Item>
      </Form>
    </StyledPatientForm>
  );
};

const StyledPatientForm = styled.div`
  label {
    font-weight: 600;
  }
  .ant-radio-wrapper {
    font-weight: 400 !important;
  }
`;

const StyledDateRow = styled(Row)`
  .ant-form-item {
    margin: 0;
  }
  margin-bottom: 24px;
`;
