export const LoginScreenLogo = () => (
  <svg
    width="179"
    height="151"
    viewBox="0 0 179 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.38414 71.7967C5.60995 71.7967 4.2793 71.4896 3.39221 70.8755C2.50511 70.3296 1.92509 69.579 1.65214 68.6236C1.37918 67.6683 1.24271 66.6447 1.24271 65.5529V6.39046C1.24271 5.29865 1.34506 4.3092 1.54978 3.4221C1.82273 2.46676 2.40276 1.71614 3.28985 1.17024C4.17695 0.556095 5.57583 0.249023 7.4865 0.249023L26.7297 0.351385C31.711 0.351385 36.283 1.20436 40.4455 2.91031C44.6763 4.61627 48.327 7.03872 51.3977 10.1777C54.4685 13.3166 56.8227 17.0697 58.4604 21.437C60.1663 25.736 61.0193 30.4785 61.0193 35.6646C61.0193 40.9872 60.1663 45.8662 58.4604 50.3017C56.7544 54.7372 54.3661 58.5585 51.2954 61.7657C48.2247 64.9729 44.5739 67.4636 40.3432 69.2378C36.1806 70.9437 31.5404 71.7967 26.4226 71.7967H7.38414ZM13.6279 59.4115H26.7297C31.0969 59.4115 34.9182 58.4562 38.1937 56.5455C41.4691 54.5666 43.9939 51.8371 45.7681 48.3569C47.6105 44.8768 48.5317 40.8166 48.5317 36.1764C48.5317 31.468 47.6105 27.3737 45.7681 23.8935C43.9939 20.3452 41.435 17.6156 38.0913 15.705C34.8159 13.7261 30.9945 12.7366 26.6273 12.7366H13.6279V59.4115Z"
      fill="#165DD7"
    />
    <path
      d="M94.2534 72.5132C90.978 72.5132 87.7708 71.8991 84.6318 70.6708C81.5611 69.3743 78.7634 67.566 76.2386 65.2459C73.782 62.9258 71.8031 60.1962 70.3018 57.0573C68.8688 53.9183 68.1523 50.4723 68.1523 46.7192C68.1523 43.1026 68.8347 39.7248 70.1995 36.5858C71.5642 33.4469 73.4408 30.6832 75.8291 28.2949C78.2175 25.9066 80.947 24.0641 84.0177 22.7676C87.1567 21.4029 90.5003 20.7205 94.0487 20.7205C98.8254 20.7205 103.158 21.8805 107.048 24.2006C110.938 26.4525 114.042 29.5232 116.363 33.4128C118.683 37.3023 119.843 41.7037 119.843 46.6168C119.843 50.5064 119.126 54.0548 117.693 57.262C116.26 60.4009 114.315 63.1305 111.859 65.4506C109.402 67.7024 106.639 69.4425 103.568 70.6708C100.565 71.8991 97.4606 72.5132 94.2534 72.5132ZM94.0487 60.4351C96.3006 60.4351 98.4501 59.8892 100.497 58.7974C102.613 57.6373 104.319 55.9996 105.615 53.8842C106.98 51.7688 107.662 49.2781 107.662 46.4121C107.662 43.7508 107.048 41.3966 105.82 39.3495C104.66 37.3023 103.056 35.6987 101.009 34.5387C98.9619 33.3786 96.6076 32.7986 93.9464 32.7986C91.4215 32.7986 89.1356 33.4128 87.0884 34.6411C85.0413 35.8011 83.4036 37.4388 82.1753 39.5542C81.0152 41.6013 80.4352 43.9214 80.4352 46.5145C80.4352 49.3805 81.0835 51.8712 82.38 53.9866C83.6765 56.0337 85.3483 57.6373 87.3955 58.7974C89.5109 59.8892 91.7286 60.4351 94.0487 60.4351Z"
      fill="#165DD7"
    />
    <path
      d="M152.248 72.1038C148.973 72.1038 145.766 71.5579 142.627 70.4661C139.556 69.306 136.758 67.6342 134.234 65.4506C131.777 63.2669 129.798 60.6057 128.297 57.4667C126.864 54.2595 126.147 50.6429 126.147 46.6168C126.147 42.5908 126.864 39.0083 128.297 35.8693C129.798 32.7304 131.777 30.0691 134.234 27.8855C136.69 25.7019 139.454 24.03 142.524 22.87C145.595 21.7099 148.768 21.1299 152.044 21.1299C154.432 21.1299 156.684 21.4711 158.799 22.1535C160.983 22.8359 162.859 23.6547 164.429 24.61C165.998 25.5654 167.124 26.5207 167.807 27.476C168.694 28.2949 169.444 29.0796 170.059 29.8303C170.673 30.5809 170.877 31.5021 170.673 32.5939C170.468 33.2763 170.161 33.9245 169.751 34.5387C169.342 35.0846 168.83 35.6646 168.216 36.2788C166.578 38.1212 164.975 38.8036 163.405 38.3259C162.518 37.8482 161.631 37.3365 160.744 36.7906C159.857 36.2446 158.936 35.7329 157.98 35.2552C157.093 34.7093 156.172 34.2657 155.217 33.9245C154.261 33.5834 153.204 33.4128 152.044 33.4128C149.451 33.4128 147.131 33.9928 145.083 35.1528C143.104 36.2446 141.535 37.78 140.375 39.7589C139.283 41.7378 138.737 44.0238 138.737 46.6168C138.737 49.2099 139.283 51.53 140.375 53.5771C141.535 55.556 143.104 57.1255 145.083 58.2856C147.062 59.3774 149.314 59.9233 151.839 59.9233C153.067 59.9233 154.227 59.7868 155.319 59.5139C156.479 59.1727 157.503 58.7632 158.39 58.2856C159.277 57.7397 159.993 57.1938 160.539 56.6479C161.29 56.1702 161.972 55.7266 162.586 55.3172C163.201 54.9078 163.849 54.7031 164.531 54.7031C165.35 54.7031 166.135 55.0101 166.885 55.6243C167.704 56.1702 168.591 57.0573 169.547 58.2856C170.434 59.3091 170.912 60.2986 170.98 61.2539C171.116 62.2093 170.912 63.1305 170.366 64.0176C169.888 64.9047 169.069 65.7235 167.909 66.4741C167.09 67.293 165.862 68.146 164.224 69.0331C162.655 69.8519 160.812 70.5684 158.697 71.1826C156.65 71.7967 154.5 72.1038 152.248 72.1038Z"
      fill="#165DD7"
    />
    <path
      d="M5.63862 150.164C4.15837 150.164 3.04819 149.936 2.30807 149.48C1.56795 149.025 1.08402 148.399 0.856294 147.602C0.685496 146.805 0.600098 145.922 0.600098 144.954V95.6793C0.600098 94.7684 0.685496 93.9429 0.856294 93.2027C1.08402 92.4057 1.56795 91.7794 2.30807 91.324C3.04819 90.8116 4.21531 90.5554 5.80941 90.5554C7.00499 90.5554 7.88745 90.6408 8.45677 90.8116C9.08303 90.9255 9.56695 91.1247 9.90855 91.4094C10.2501 91.6371 10.5917 91.9502 10.9333 92.3488L31.7706 121.47H29.8064L50.7291 92.2634C51.1276 91.8079 51.5261 91.4663 51.9247 91.2386C52.3232 91.0109 52.8356 90.8401 53.4618 90.7262C54.0881 90.6123 54.9136 90.5554 55.9384 90.5554C57.4756 90.5554 58.6142 90.8116 59.3543 91.324C60.0945 91.7794 60.5499 92.4057 60.7207 93.2027C60.9485 93.9998 61.0623 94.8823 61.0623 95.8501V145.125C61.0623 145.979 60.9485 146.805 60.7207 147.602C60.5499 148.399 60.0945 149.025 59.3543 149.48C58.6142 149.936 57.4471 150.164 55.853 150.164C54.3158 150.164 53.1772 149.907 52.4371 149.395C51.6969 148.94 51.213 148.313 50.9853 147.516C50.8145 146.719 50.7291 145.865 50.7291 144.954V108.318L35.1865 130.778C35.0157 131.006 34.7595 131.319 34.418 131.718C34.0764 132.059 33.6209 132.372 33.0516 132.657C32.4822 132.942 31.7137 133.084 30.7458 133.084C29.8349 133.084 29.0948 132.942 28.5254 132.657C27.9561 132.372 27.5007 132.059 27.1591 131.718C26.8744 131.376 26.5897 131.034 26.3051 130.693L10.8479 108.318V145.125C10.8479 145.979 10.7341 146.805 10.5063 147.602C10.3355 148.399 9.88008 149.025 9.13996 149.48C8.39984 149.936 7.23272 150.164 5.63862 150.164Z"
      fill="#165DD7"
    />
    <path
      d="M75.7776 150.249C74.2404 150.249 73.1017 149.993 72.3616 149.48C71.6215 149.025 71.166 148.427 70.9952 147.687C70.8245 146.89 70.739 146.008 70.739 145.04V113.271C70.739 112.361 70.8245 111.535 70.9952 110.795C71.223 109.998 71.6784 109.372 72.3616 108.916C73.1017 108.461 74.2689 108.233 75.863 108.233C77.4571 108.233 78.5957 108.461 79.2789 108.916C80.019 109.372 80.4745 109.998 80.6453 110.795C80.8161 111.592 80.9015 112.474 80.9015 113.442V145.125C80.9015 146.093 80.8161 146.975 80.6453 147.772C80.4745 148.513 80.019 149.11 79.2789 149.566C78.5957 150.021 77.4286 150.249 75.7776 150.249ZM75.7776 99.9492C74.1265 99.9492 72.9025 99.7215 72.1054 99.2661C71.3653 98.7537 70.8814 98.0705 70.6537 97.2165C70.4829 96.3625 70.3975 95.4231 70.3975 94.3983C70.3975 93.3166 70.4829 92.3772 70.6537 91.5802C70.8814 90.7831 71.3938 90.1569 72.1908 89.7014C72.9879 89.189 74.2119 88.9328 75.863 88.9328C77.5709 88.9328 78.795 89.189 79.5351 89.7014C80.2752 90.2138 80.7592 90.897 80.9869 91.751C81.2146 92.548 81.3285 93.4874 81.3285 94.5691C81.3285 95.537 81.2146 96.4479 80.9869 97.3019C80.7592 98.1559 80.2752 98.8106 79.5351 99.2661C78.795 99.7215 77.5425 99.9492 75.7776 99.9492Z"
      fill="#165DD7"
    />
    <path
      d="M95.2825 150.164C93.7453 150.164 92.6067 149.907 91.8665 149.395C91.1833 148.94 90.7564 148.342 90.5856 147.602C90.4148 146.805 90.3294 145.922 90.3294 144.954V113.015C90.3294 112.104 90.4148 111.279 90.5856 110.539C90.7564 109.799 91.2118 109.229 91.9519 108.831C92.6921 108.375 93.8307 108.148 95.3679 108.148C96.7343 108.148 97.759 108.318 98.4422 108.66C99.1254 109.001 99.5809 109.428 99.8086 109.941C100.036 110.453 100.15 110.966 100.15 111.478C100.207 111.99 100.264 112.389 100.321 112.674L99.9794 115.406C100.321 114.495 100.89 113.613 101.687 112.759C102.484 111.905 103.452 111.136 104.591 110.453C105.73 109.713 106.954 109.144 108.263 108.745C109.629 108.29 111.024 108.062 112.448 108.062C115.123 108.062 117.515 108.546 119.621 109.514C121.728 110.482 123.521 111.848 125.001 113.613C126.538 115.378 127.705 117.484 128.503 119.933C129.3 122.324 129.698 125.028 129.698 128.045V145.04C129.698 146.008 129.584 146.89 129.357 147.687C129.186 148.427 128.759 149.025 128.076 149.48C127.392 149.936 126.225 150.164 124.574 150.164C122.98 150.164 121.813 149.907 121.073 149.395C120.333 148.94 119.877 148.342 119.706 147.602C119.536 146.805 119.45 145.922 119.45 144.954V127.96C119.45 125.91 119.08 124.146 118.34 122.665C117.657 121.185 116.632 120.046 115.266 119.249C113.956 118.452 112.277 118.054 110.227 118.054C108.235 118.054 106.498 118.481 105.018 119.335C103.538 120.132 102.399 121.27 101.602 122.751C100.805 124.231 100.406 125.967 100.406 127.96V145.04C100.406 146.008 100.321 146.89 100.15 147.687C99.9794 148.427 99.5239 149.025 98.7838 149.48C98.1006 149.936 96.9335 150.164 95.2825 150.164Z"
      fill="#165DD7"
    />
    <path
      d="M156.135 150.164C153.516 150.164 151.04 149.623 148.705 148.541C146.371 147.459 144.322 145.979 142.557 144.1C140.792 142.165 139.397 139.916 138.372 137.354C137.347 134.735 136.835 131.945 136.835 128.985C136.835 126.138 137.347 123.462 138.372 120.957C139.397 118.395 140.792 116.175 142.557 114.296C144.378 112.361 146.456 110.852 148.791 109.77C151.125 108.688 153.573 108.148 156.135 108.148C158.868 108.148 161.316 108.688 163.479 109.77C165.643 110.852 167.465 112.361 168.945 114.296C170.482 116.175 171.649 118.395 172.446 120.957C173.243 123.462 173.67 126.167 173.727 129.07C173.727 131.974 173.329 134.706 172.532 137.268C171.791 139.773 170.653 141.994 169.116 143.93C167.635 145.865 165.785 147.402 163.565 148.541C161.401 149.623 158.925 150.164 156.135 150.164ZM157.501 140.172C159.494 140.172 161.259 139.717 162.796 138.806C164.39 137.838 165.643 136.528 166.554 134.877C167.521 133.226 168.005 131.347 168.005 129.241C168.005 127.078 167.521 125.17 166.554 123.519C165.643 121.868 164.39 120.559 162.796 119.591C161.259 118.623 159.494 118.139 157.501 118.139C155.509 118.139 153.715 118.623 152.121 119.591C150.527 120.502 149.246 121.811 148.278 123.519C147.367 125.17 146.912 127.078 146.912 129.241C146.912 131.347 147.367 133.226 148.278 134.877C149.246 136.528 150.527 137.838 152.121 138.806C153.772 139.717 155.566 140.172 157.501 140.172ZM173.3 150.164C171.706 150.164 170.539 149.907 169.799 149.395C169.059 148.826 168.575 147.943 168.347 146.748V94.1421C168.347 93.1743 168.404 92.3203 168.518 91.5802C168.689 90.7831 169.116 90.1569 169.799 89.7014C170.539 89.189 171.678 88.9328 173.215 88.9328C174.866 88.9328 176.033 89.1606 176.716 89.616C177.399 90.0715 177.826 90.6977 177.997 91.4948C178.225 92.2349 178.339 93.0889 178.339 94.0567V144.954C178.339 145.922 178.253 146.805 178.082 147.602C177.912 148.399 177.456 149.025 176.716 149.48C176.033 149.936 174.894 150.164 173.3 150.164Z"
      fill="#165DD7"
    />
  </svg>
);
