import { Col, Form, Input, Row, theme, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useEventFormContext } from "../../contexts";
import { Event, Patient } from "../../model";
import { PatientFill } from "./PatientFill";
import { newPatient } from "../../utils";

export const EventForm: React.FC = () => {
  const { formValues, updateValues } = useEventFormContext();
  const { t } = useTranslation();
  const [patient, setPatient] = useState<Patient>(newPatient());

  const handleChangePatient = (patient: Patient) => {
    setPatient(patient);
    updateValues({
      ...formValues,
      patient_id: patient.patient_id,
      patient_name: `${patient.last_name} ${patient.first_name} `,
      patient_first_name: patient.first_name,
      patient_last_name: patient.last_name,
    } as Event);
  };

  const handleFormValueChanges = (_: any, newValues: any) => {
    const formattedNewValues = {
      ...newValues,
    };

    updateValues({ ...formValues, ...formattedNewValues });
  };

  const {
    token: { colorBgContainer, colorText, colorBorder },
  } = theme.useToken();

  return (
    <StyledEventForm>
      <Form
        onValuesChange={handleFormValueChanges}
        initialValues={formValues}
        size="large"
      >
        <Form.Item name="title" required>
          <Input placeholder={t("add_title") as string} />
        </Form.Item>
        <Row justify="center" gutter={16} style={{ marginTop: 16 }}>
          <Col>
            <Form.Item name="start_date" label={t("from")}>
              <input
                style={{
                  backgroundColor: colorBgContainer,
                  border: `1px solid ${colorBorder}`,
                  color: colorText,
                  fontSize: 16,
                  width: 150,
                }}
                type="time"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="end_date" label={t("to")}>
              {
                <input
                  style={{
                    backgroundColor: colorBgContainer,
                    color: colorText,
                    fontSize: 16,
                    width: 150,
                    border: `1px solid ${colorBorder}`,
                  }}
                  type="time"
                />
              }
            </Form.Item>
          </Col>
        </Row>
        <Form.Item wrapperCol={{ span: 24 }} name="description">
          <Input.TextArea
            placeholder={t("note") as string}
            style={{ width: "100%" }}
            size="large"
            rows={4}
          />
        </Form.Item>
        <Typography.Title level={5}>{t("link_patient")}</Typography.Title>
        <PatientFill patient={patient} onChange={handleChangePatient} />
      </Form>
    </StyledEventForm>
  );
};

const StyledEventForm = styled.div`
  label {
    font-weight: 600;
    ::after {
      content: " " !important;
    }
  }
  .ant-radio-wrapper {
    font-weight: 400 !important;
  }
`;
