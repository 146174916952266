import i18n from "../translations/i18n";

interface PhoneIndicative {
  code: string;
  flag: string;
}

export const phone_indicatives: PhoneIndicative[] = [
  {
    code: "+216",
    flag: "tn",
  },
  {
    code: "+33",
    flag: "fr",
  },
  {
    code: "+1",
    flag: "us-ca",
  },
];

export const getLocale = () => (i18n.resolvedLanguage === "en" ? "en" : "fr");
