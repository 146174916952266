import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

export const useScreen = () => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobile = useMediaQuery({ query: "(max-width:650px )" });
  const antXs = useMediaQuery({ query: "(max-width:480px )" });
  const antSm = useMediaQuery({ query: "(max-width:576px )" });
  const antMd = useMediaQuery({ query: "(max-width:768px )" });
  const antLg = useMediaQuery({ query: "(max-width:992px )" });
  const antXl = useMediaQuery({ query: "(max-width:1200px )" });
  const antXxl = useMediaQuery({ query: "(max-width:1600px )" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isMobileDevice =
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    });
    window.addEventListener("orientationchange", () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () =>
        setScreenHeight(window.innerHeight)
      );
      window.removeEventListener("orientationchange", () => {
        setScreenHeight(window.innerHeight);
        setScreenWidth(window.innerWidth);
      });
    };
  });

  return {
    isMobile,
    isMobileDevice,
    isPortrait,
    screenHeight,
    screenWidth,
    antXs,
    antSm,
    antMd,
    antLg,
    antXl,
    antXxl,
  };
};
