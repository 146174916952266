type Config = { url: string };
type ConfigMap = Record<typeof process.env.NODE_ENV, Config>;

const config: ConfigMap = {
  development: {
    url: "http://127.0.0.1:8080/docmind-7a3fc/us-central1/api",
  },
  production: {
    url: "https://us-central1-docmind-7a3fc.cloudfunctions.net/api",
  },
  test: { url: "https://us-central1-docmind-7a3fc.cloudfunctions.net/api" },
};

export const getConfig = (): Config =>
  config[process.env.NODE_ENV] || {
    url: "https://us-central1-docmind-7a3fc.cloudfunctions.net/api",
  };
