import { Dropdown, Space, Typography } from "antd";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import { Workspace } from "../../model";
import { useWorkSpace } from "../../contexts";

export const WorkspaceSelector: React.FC = () => {
  const { workspaces, currentWorkspace, handleChangeWorkspace } =
    useWorkSpace();

  const getWorkspacesMenuItems = (workspaces?: Workspace[]) => {
    if (!workspaces) {
      return [];
    }
    return workspaces.map((workspace) => ({
      label: workspace.name,
      key: workspace.workspace_id,
      onClick: () => handleChangeWorkspace(workspace),
    }));
  };

  const workspaceItems = getWorkspacesMenuItems(workspaces);

  if (!workspaces || !currentWorkspace) return <></>;

  return (
    <StyledWorkspaceSelector>
      <Dropdown menu={{ items: workspaceItems }} trigger={["click"]}>
        <Typography.Text onClick={(e) => e.preventDefault()}>
          <Space>
            {currentWorkspace?.name}
            <DownOutlined />
          </Space>
        </Typography.Text>
      </Dropdown>
    </StyledWorkspaceSelector>
  );
};

const StyledWorkspaceSelector = styled.div`
  :hover {
    cursor: pointer;
  }
  .ant-typography {
    font-weight: bold;
  }
  display: inline;
`;
