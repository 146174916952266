import { Card, Col, Empty, message, Row, Skeleton, Typography } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { browseEvents } from "../../api";
import { useWorkSpace } from "../../contexts";
import { useBoolean } from "../../hooks";
import { EventCard } from "./EventCard";
import { deleteEvent as deleteEventApi } from "../../api";
import { t } from "i18next";
import { useState } from "react";
import { Event } from "../../model";
import { EventInfoModal } from "../event-form";

interface Props {
  startDate: string;
  endDate: string;
  emptyMessage?: string;
}

export const EventsList: React.FC<Props> = ({
  startDate,
  endDate,
  emptyMessage,
}) => {
  const { currentWorkspace } = useWorkSpace();
  const [isEventInfoModalOpen, openEventInfoModal, closeEventInfoModal] =
    useBoolean();

  const [focusedEvent, setFocusedEvent] = useState<Event>();

  const { data, isLoading } = useQuery(
    ["events-list", startDate, endDate, currentWorkspace?.workspace_id],
    () => browseEvents(currentWorkspace?.workspace_id!, startDate, endDate),
    {
      enabled: Boolean(currentWorkspace?.workspace_id),
    }
  );

  const [messageApi, contextHolder] = message.useMessage();

  const queryClient = useQueryClient();

  const { mutate: deleteEvent, isLoading: isLoadingDeleting } = useMutation(
    () =>
      deleteEventApi(currentWorkspace?.workspace_id!, focusedEvent?.event_id!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("events-list");
        queryClient.invalidateQueries("month-events-list");
        queryClient.invalidateQueries("events-stats");
        closeEventInfoModal();
        messageApi.open({
          type: "success",
          content: t("delete_success_message"),
        });
      },
    }
  );

  const handleOnTarget = (event: Event) => {
    setFocusedEvent(event);
    openEventInfoModal();
  };

  if (isLoading) {
    return (
      <Row gutter={[16, 16]}>
        {Array.from({ length: 6 }).map((_, i) => (
          <Col span={24} key={i}>
            <Card>
              <Skeleton title={false} paragraph={{ rows: 1, width: "100%" }} />
            </Card>
          </Col>
        ))}
      </Row>
    );
  }

  const events = data?.data;

  return (
    <>
      {contextHolder}
      <EventInfoModal
        isDeleting={isLoadingDeleting}
        handleDeleteEvent={deleteEvent}
        event={focusedEvent!}
        onClose={closeEventInfoModal}
        visible={isEventInfoModalOpen}
      />
      <StyledEventsList gutter={[16, 16]}>
        {events?.map((event) => (
          <Col span={24} key={event.event_id}>
            <EventCard onTarget={handleOnTarget} event={event} />
          </Col>
        ))}

        {!events?.length && (
          <Row
            style={{ width: "100%", minHeight: "35vh" }}
            justify="center"
            align="middle"
          >
            <Empty
              description={
                <Typography.Title level={5}>
                  {emptyMessage || t("no_consultation_planned")}
                </Typography.Title>
              }
            />
          </Row>
        )}
      </StyledEventsList>
    </>
  );
};

const StyledEventsList = styled(Row)`
  text-align: left;
  padding-bottom: 48px;
  .ant-col {
    padding: 0px !important;
  }
`;
