const year_month_day_en = "MMMM D, YYYY";
const year_month_day_fr = "DD MMMM, YYYY";
const hours_minute_en = "hh:mm A";
const hours_minute_fr = "HH:mm";

export const getDateFormat = (locale: string) => {
  if (locale === "en") return year_month_day_en;
  if (locale === "fr") return year_month_day_fr;
};

export const getTimeFormat = (locale: string) => {
  if (locale === "en") return hours_minute_en;
  if (locale === "fr") return hours_minute_fr;
};
