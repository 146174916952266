import { Typography } from "antd";
import styled from "styled-components";

interface Props {
  label: string;
}

export const LabelDisplay: React.FC<Props> = ({ label }) => {
  return <StyledLabelDisplay strong>{label}: </StyledLabelDisplay>;
};

const StyledLabelDisplay = styled(Typography.Text)``;
