import { message, Modal, Typography } from "antd";
import styled from "styled-components";
import {
  EventFormProvider,
  useEventFormContext,
  useWorkSpace,
} from "../../contexts";
import { isValidEvent, newEvent } from "../../utils";
import { EventForm } from "./EventForm";
import { useMutation, useQueryClient } from "react-query";
import { createEvent as createEventApi } from "../../api";
import { useTranslation } from "react-i18next";
import loadash from "lodash";
import { Event } from "../../model";
import { BsCalendar2Plus } from "react-icons/bs";
import dayjs from "dayjs";
interface Props {
  visible: boolean;
  onClose: () => void;
  date: dayjs.Dayjs;
}
export const NewEventModal: React.FC<Props> = (props) => (
  <EventFormProvider initialValues={{ ...newEvent() }}>
    <NewEventCardContent {...props} />
  </EventFormProvider>
);

const NewEventCardContent: React.FC<Props> = ({ visible, onClose, date }) => {
  const { currentWorkspace } = useWorkSpace();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { formValues, updateValues } = useEventFormContext();
  const [messageApi, contextHolder] = message.useMessage();

  const { mutate: createEvent, isLoading } = useMutation(
    (event: Event) => createEventApi(currentWorkspace?.workspace_id!, event),
    {
      onError: (e) => {
        messageApi.open({
          type: "error",
          content: t("network_error"),
        });
      },
      onSuccess: (e) => {
        queryClient.invalidateQueries("events-list");
        queryClient.invalidateQueries("month-events-list");
        queryClient.invalidateQueries("events-stats");
        handleCloseModal();
        messageApi.open({
          type: "success",
          content: t("create_success_message"),
        });
      },
    }
  );

  const handleCloseModal = () => {
    onClose();
    updateValues(newEvent());
  };

  const handleSaveClick = () => {
    if (formValues.end_date < formValues.start_date) {
      return messageApi.error(t("end_time_error"));
    }

    const startHour = formValues.start_date.split(":")[0];
    const startMinutes = formValues.start_date.split(":")[1];
    const endHour = formValues.end_date.split(":")[0];
    const endMinutes = formValues.end_date.split(":")[1];
    const startDate = dayjs(date)
      .set("hours", Number(startHour))
      .set("minutes", Number(startMinutes))
      .toISOString();
    const endDate = dayjs(date)
      .set("hours", Number(endHour))
      .set("minutes", Number(endMinutes))
      .toISOString();

    const cleanedForm = loadash(formValues)
      .omitBy((item) => {
        if (typeof item === "string") {
          return loadash.isEmpty(item.trim());
        }
      })
      .value();

    const newEvent = {
      ...cleanedForm,
      type: "consultation",
      start_date: startDate,
      end_date: endDate,
    } as Event;

    createEvent(newEvent);
  };

  return (
    <StyledNewFormModal>
      {contextHolder}
      <StyledModal
        maskClosable={false}
        destroyOnClose
        cancelText={t("cancel")}
        okText={t("create")}
        okButtonProps={{
          size: "large",
          disabled: !isValidEvent(formValues),
          loading: isLoading,
        }}
        cancelButtonProps={{ size: "large" }}
        title={
          <StyledModalTitle level={3}>
            <BsCalendar2Plus />
            {t("new_consultation")}
          </StyledModalTitle>
        }
        open={visible}
        onOk={handleSaveClick}
        onCancel={() => {
          onClose();
          updateValues(newEvent());
        }}
      >
        <EventForm />
      </StyledModal>
    </StyledNewFormModal>
  );
};

const StyledNewFormModal = styled.div``;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-bottom: 0px;
  }
  .ant-modal-footer {
    padding: 12px 16px;
    button {
      min-width: 110px;
      border-radius: 32px;
    }
  }
`;

const StyledModalTitle = styled(Typography.Title)`
  margin: 0px !important;
  margin-bottom: 24px !important;
  display: flex;
  align-items: center;
  svg {
    font-size: 28px;
    margin-right: 12px;
  }
`;
