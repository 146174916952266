import styled from "styled-components";
import { Input, message, Modal, Typography, Upload, UploadProps } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { storage } from "../../api";
import { ref, uploadBytes } from "@firebase/storage";
import { useMutation, useQueryClient } from "react-query";
import { t } from "i18next";
import { useState } from "react";

interface Props {
  patient_id?: string;
  workspace_id?: string;
}

const { Dragger } = Upload;

export const FileDragger: React.FC<Props> = ({ patient_id, workspace_id }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const [fileOptions, setFileOptions] = useState<any>();

  const handleOk = () => {
    addFile(fileName);
  };

  const closeAndReset = () => {
    setFileOptions(undefined);
    setIsModalOpen(false);
    setFileName("");
  };

  const { mutate: addFile, isLoading: isLoadingAdd } = useMutation(
    (name: string) => {
      const fileRef = ref(
        storage,
        `patients/${workspace_id}/${patient_id}/${name}`
      );
      message.loading("Uploading File");
      return uploadBytes(fileRef, fileOptions?.file as any);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("files-list");
        closeAndReset();
        message.destroy();
        messageApi.open({
          type: "success",
          content: t("create_success_message"),
        });
      },
      onError: () => {
        message.error("Error uploading");
      },
    }
  );

  const queryClient = useQueryClient();

  const props: UploadProps = {
    name: "file",
    accept: "image/*,.pdf",
    multiple: false,
    customRequest: (options) => {
      setFileOptions(options);
      setIsModalOpen(true);
    },
    itemRender: () => null,
    onDrop(e) {
      const file = e.dataTransfer.files[0];
      if (file.type !== "application/pdf" && !file.type?.includes("image")) {
        messageApi.info(t("file_dragger_rules"));
      }
    },
  };
  return (
    <StyledFileDragger>
      <Modal
        destroyOnClose
        open={isModalOpen}
        title={
          <StyledDeleteModalTitle>
            <Typography.Title style={{ margin: 0 }} level={4}>
              {t("choose_file_name")}
            </Typography.Title>
          </StyledDeleteModalTitle>
        }
        onOk={handleOk}
        okText={t("upload")}
        cancelText={t("cancel")}
        onCancel={closeAndReset}
        cancelButtonProps={{ size: "large" }}
        okButtonProps={{
          loading: isLoadingAdd,
          size: "large",
          disabled: !fileName.trim().length,
        }}
        okType="primary"
      >
        <Input
          style={{ marginTop: 12, marginBottom: 12 }}
          size="large"
          value={fileName}
          onChange={(value) => setFileName(value.target.value)}
        />
      </Modal>
      {contextHolder}
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t("file_dragger_placeholder")}</p>
        <p className="ant-upload-hint">{t("file_dragger_rules")}</p>
      </Dragger>
    </StyledFileDragger>
  );
};

const StyledFileDragger = styled.div``;

const StyledDeleteModalTitle = styled.div`
  display: flex;
  gap: 16px;
`;
