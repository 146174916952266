import { Button, Result } from "antd";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Paths } from "../../router";

export const UnhandledError: React.FC = () => {
  const navigate = useNavigate();
  return (
    <StyledNotFound>
      <Result
        status="500"
        title="500"
        subTitle={t("error_500_message")}
        extra={
          <Button
            size="large"
            onClick={() => navigate(Paths.Dashboard)}
            type="primary"
          >
            {t("go_dashboard")}
          </Button>
        }
      />
    </StyledNotFound>
  );
};

const StyledNotFound = styled.div``;
