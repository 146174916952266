import {
  Event,
  Patient,
  StatsForEvents,
  StatsForPatients,
  Template,
  User,
  Workspace,
} from "../model";
import { apiClient } from "./client";

export const browseWorkspaces = () => apiClient.get<Workspace[]>("/workspaces");

export const readWorkspace = (workspace_id: string) =>
  apiClient.get<Workspace[]>(`/workspaces/${workspace_id}`);

export const readTemplate = (template_id: string) =>
  apiClient.get<Template>(`/templates/${template_id}`);

export const browsePatients = (base_id: string, params?: any) => {
  const offset = params.pageParam * 15;
  return apiClient.get<Patient[]>("/patients", {
    params: {
      base_id,
      ...params,
      offset: offset || 0,
      limit: 15,
    },
  });
};

export const readPatient = (patient_id: string) => {
  return apiClient.get<Patient>(`/patients/${patient_id}`);
};

export const createPatient = (patient: Patient) =>
  apiClient.post("/patients", patient);

export const updatePatient = (patient: Partial<Patient>) =>
  apiClient.patch(`/patients/${patient.patient_id}`, patient);

export const deletePatient = (patient_id: string) =>
  apiClient.delete(`/patients/${patient_id}`);

export const addToPatientCollection = (
  patient_id: string,
  collection_name: string,
  data: any
) => apiClient.post(`/patients/${patient_id}/${collection_name}`, data);

export const browsePatientCollection = (
  patient_id: string,
  collection_name: string
) => apiClient.get(`/patients/${patient_id}/${collection_name}`);

export const editDocInPatientCollection = (
  patient_id: string,
  collection_name: string,
  doc_id: string,
  data: any
) =>
  apiClient.put(`/patients/${patient_id}/${collection_name}/${doc_id}`, {
    data,
  });

export const deleteDocInPatientCollection = (
  patient_id: string,
  collection_name: string,
  doc_id: string
) => apiClient.delete(`/patients/${patient_id}/${collection_name}/${doc_id}`);

export const browseEvents = (
  workspace_id: string,
  startDate: string,
  endDate: string,
  params?: any
) =>
  apiClient.get<Event[]>(`workspaces/${workspace_id}/events`, {
    params: {
      ...params,
      startDate,
      endDate,
    },
  });

export const createEvent = (workspace_id: string, event: Event) =>
  apiClient.post(`workspaces/${workspace_id}/events`, event);

export const deleteEvent = (workspace_id: string, event_id: string) =>
  apiClient.delete(`workspaces/${workspace_id}/events/${event_id}`);

export const readSelfProfile = () => apiClient.get<User>("/self");

export const getPatientsStats = (
  workspace_id: string,
  startDate: string,
  endDate: string
) =>
  apiClient.get<StatsForPatients>(`workspaces/${workspace_id}/patients/stats`, {
    params: {
      startDate,
      endDate,
    },
  });

export const getEventsStats = (
  workspace_id: string,
  startDate: string,
  endDate: string
) =>
  apiClient.get<StatsForEvents>(`workspaces/${workspace_id}/events/stats`, {
    params: {
      startDate,
      endDate,
    },
  });
