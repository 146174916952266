import React, { useContext, useState } from "react";
import { Event } from "../model";
import { newEvent } from "../utils";

interface EventFormContextProps {
  formValues: Event;
  updateValues: (formValues: Event) => void;
}

const EventFormContext = React.createContext<EventFormContextProps>(
  {} as EventFormContextProps
);

export const useEventFormContext = () => useContext(EventFormContext);

export const EventFormProvider = ({
  children,
  initialValues,
}: React.PropsWithChildren & { initialValues?: Event }) => {
  const [formValues, setFormValues] = useState<Event>(
    initialValues || newEvent()
  );

  const updateValues = (event: Event) => setFormValues(event);

  const value: EventFormContextProps = {
    formValues,
    updateValues,
  };

  return (
    <EventFormContext.Provider value={value}>
      {children}
    </EventFormContext.Provider>
  );
};
