import { getDownloadURL, StorageReference } from "@firebase/storage";
import { Image, Spin } from "antd";
import { useQuery } from "react-query";
import styled from "styled-components";
import { FilePdfFilled } from "@ant-design/icons";

interface Props {
  file: StorageReference;
  isImage?: boolean;
}

export const FilePreview: React.FC<Props> = ({ file, isImage }) => {
  const { data, isLoading } = useQuery([file.fullPath], () =>
    getDownloadURL(file)
  );

  if (isLoading) {
    return <Spin />;
  }
  if (isImage) {
    return <Image height={150} src={data} />;
  }
  return (
    <StyledFilePreview href={data} target="_blank">
      <FilePdfFilled />
    </StyledFilePreview>
  );
};

const StyledFilePreview = styled.a`
  height: 150px !important;
  svg {
    font-size: 146px;
  }
`;
