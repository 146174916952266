import { Button, Col, Input, Row } from "antd";
import { t } from "i18next";
import { useState } from "react";
import styled from "styled-components";
import { Patient } from "../../model";
import { newPatient } from "../../utils";

import { PatientsSelector } from "../selectors";

interface Props {
  onChange: (patient: Patient) => void;
  patient: Patient;
}

export const PatientFill: React.FC<Props> = ({ onChange, patient }) => {
  const [mode, setMode] = useState<"new" | "existing">("existing");

  return (
    <StyledPatientFill>
      {mode === "new" ? (
        <>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Input
                onChange={(v) =>
                  onChange({ ...patient, last_name: v.target.value })
                }
                placeholder={t("last_name") as string}
              />
            </Col>
            <Col span={12}>
              <Input
                onChange={(v) =>
                  onChange({ ...patient, first_name: v.target.value })
                }
                placeholder={t("first_name") as string}
              />
            </Col>
          </Row>
          <Row justify="end">
            <Button
              onClick={() => {
                setMode("existing");
                onChange(newPatient());
              }}
              type="link"
            >
              Patient existant?
            </Button>
          </Row>
        </>
      ) : (
        <>
          <PatientsSelector onChange={onChange} />
          <Row justify="end">
            {/* <Button
              onClick={() => {
                setMode("new");
                onChange(newPatient());
              }}
              type="link"
            >
              {t("new_patient")}?
            </Button> */}
          </Row>
        </>
      )}
    </StyledPatientFill>
  );
};

const StyledPatientFill = styled.div`
  .ant-form-item {
    margin: 0px;
  }
`;
