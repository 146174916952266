import { theme } from "antd";
import styled from "styled-components";
import { Event } from "../../model";

interface Props {
  events: Event[];
}

export const DateCellRender: React.FC<Props> = ({ events }) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  return (
    <StyledDateCellRender>
      {Boolean(events.length) && (
        <StyledEventsNumber style={{ backgroundColor: colorPrimary }} />
      )}
    </StyledDateCellRender>
  );
};

const StyledDateCellRender = styled.div`
  height: 80%;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledEventsNumber = styled.div`
  opacity: 0.3;
  border-radius: 50%;
  padding: 3px;
`;
