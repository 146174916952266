import { Patient } from "../model";

export const newPatient = (): Patient => ({
  first_name: "",
  last_name: "",
  gender: "M",
  birthdate: "",
  patient_id: "-1",
  phone_number: {
    code: "",
    number: "",
  },
  address: "",
  email: "",
  workspace_id: "",
  owner_id: "",
  template_id: "",
  created_at: "",
  created_by: "",
  updated_at: "",
});

export const isValidPatient = (patient: Patient): boolean =>
  !(Boolean(patient.first_name) && Boolean(patient.last_name));
