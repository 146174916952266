import { Button, Col, Empty, message, Modal, Row, Typography } from "antd";
import styled from "styled-components";
import { NoteCard } from "./NoteCard";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { AddNoteForm } from "./AddNoteForm";
import { useScreen } from "../../hooks";
import { newNote } from "../../utils";
import { Note, TemplateCollection } from "../../model";
import { t } from "i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addToPatientCollection,
  browsePatientCollection,
  deleteDocInPatientCollection,
  editDocInPatientCollection,
} from "../../api";
import { NotesLoader } from "./NotesLoader";
import { DeleteOutlined } from "@ant-design/icons";

interface Props {
  patientId: string;
  browserType: TemplateCollection;
}

export const Notes: React.FC<Props> = ({ patientId, browserType }) => {
  const [addingNote, setAddingNote] = useState<boolean>(false);
  const [newNoteValue, setnewNoteValue] = useState<string>("");

  const queryClient = useQueryClient();

  const handleAddNote = () => setAddingNote(true);
  const handleCancelAddNote = () => {
    setAddingNote(false);
    setnewNoteValue("");
  };

  const [messageApi, contextHolder] = message.useMessage();
  const [deleteModalOpen, setOpenDeleteModal] = useState(false);
  const [targetNoteId, setTargetNoteId] = useState<string>("");

  const { data, isLoading } = useQuery([browserType.name, patientId], () =>
    browsePatientCollection(patientId, browserType.name)
  );

  const { mutate: addNote, isLoading: isLoadingAdd } = useMutation(
    (data: any) => addToPatientCollection(patientId, browserType.name, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(browserType.name);
        handleCancelAddNote();
        messageApi.open({
          type: "success",
          content: t("create_success_message"),
        });
      },
    }
  );

  const { mutate: editNote, isLoading: isLoadingEdit } = useMutation(
    ({ docId, data }: { docId: string; data: string }) =>
      editDocInPatientCollection(patientId, browserType.name, docId, {
        note: data,
      })
  );

  const { mutate: deleteNote, isLoading: isLoadingDelete } = useMutation(
    (docId: string) =>
      deleteDocInPatientCollection(patientId, browserType.name, docId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(browserType.name);
        setTargetNoteId("");
        setOpenDeleteModal(false);
        messageApi.success({
          type: "success",
          content: t("delete_success_message"),
        });
      },
    }
  );

  const handleDeleteNote = (note: Note) => {
    setOpenDeleteModal(true);
    setTargetNoteId(note.id);
  };

  const patientsNotes = data?.data as Note[];

  const handleAddSave = () => {
    addNote({ ...newNote(), note: newNoteValue.trim() });
  };

  const handleUpdateNote = (note: Note, successUpdateCallBack: () => void) => {
    editNote(
      { data: note.note, docId: note.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(browserType.name);
          successUpdateCallBack();
          messageApi.open({
            type: "success",
            content: t("edit_success_message"),
          });
        },
      }
    );
  };

  const { screenHeight } = useScreen();

  if (isLoading) return <NotesLoader />;

  return (
    <StyledNotes>
      {contextHolder}
      <StyledTopBar
        gutter={[16, 16]}
        justify="space-between"
        align="middle"
        wrap={false}
      >
        <Col flex={1}></Col>
        <Col>
          <Button
            size="large"
            disabled={addingNote}
            onClick={handleAddNote}
            icon={<PlusOutlined />}
            type="primary"
          >
            {t("add_new")}
          </Button>
        </Col>
      </StyledTopBar>
      {!patientsNotes.length && !addingNote && <Empty description="" />}
      <StyledNotesList $screenHeight={screenHeight} gutter={[24, 24]}>
        {addingNote && (
          <Col span={24}>
            <AddNoteForm onChange={setnewNoteValue} value={newNoteValue} />
            <StyledAddActionsRow justify="end">
              <Button size="large" onClick={handleCancelAddNote}>
                {t("cancel")}
              </Button>
              <Button
                size="large"
                disabled={!newNoteValue.trim().length}
                onClick={handleAddSave}
                loading={isLoadingAdd}
                type="primary"
              >
                {t("create")}
              </Button>
            </StyledAddActionsRow>
          </Col>
        )}
        {patientsNotes?.map((note) => (
          <Col key={note.id} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <NoteCard
              note={note}
              updateNote={handleUpdateNote}
              deleteNote={handleDeleteNote}
              isLoadingUpdate={isLoadingEdit}
            />
          </Col>
        ))}
      </StyledNotesList>
      <StyledDeleteModal
        destroyOnClose
        open={deleteModalOpen}
        title={
          <StyledDeleteModalTitle>
            <DeleteOutlined />
            <Typography.Title style={{ margin: 0 }} level={4}>
              {t("delete_modal_title", { entity: t("note") })}
            </Typography.Title>
          </StyledDeleteModalTitle>
        }
        onOk={() => deleteNote(targetNoteId)}
        okText={t("delete")}
        cancelText={t("no")}
        onCancel={() => setOpenDeleteModal(false)}
        cancelButtonProps={{ size: "large" }}
        okButtonProps={{ loading: isLoadingDelete, size: "large" }}
        okType="danger"
      >
        <Typography.Text style={{ marginBottom: 24 }}>
          {t("are_you_sure_delete", {
            name: t("this_note"),
          })}
        </Typography.Text>
      </StyledDeleteModal>
    </StyledNotes>
  );
};

const StyledNotesList = styled(Row)<{ $screenHeight: number }>`
  .ant-card {
    height: 100%;
  }
  .ant-card-body {
    height: 100%;
  }
`;

const StyledAddActionsRow = styled(Row)`
  margin-top: 8px;
  margin-bottom: 24px;
  button:first-of-type {
    margin-right: 8px;
  }
`;

const StyledDeleteModal = styled(Modal)`
  .ant-modal-body {
    margin-bottom: 24px;
  }
`;

const StyledTopBar = styled(Row)`
  margin-bottom: 24px;
`;

const StyledDeleteModalTitle = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

const StyledNotes = styled.div``;
