import { Button, Col, Divider, Modal, Row, Typography } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import styled from "styled-components";
import { Event } from "../../model";
import { getLocale, getTimeFormat } from "../../utils";
import { LinkOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../router";

interface Props {
  event: Event;
  onClose: () => void;
  visible: boolean;
  handleDeleteEvent: () => void;
  isDeleting: boolean;
}

export const EventInfoModal: React.FC<Props> = ({
  event,
  onClose,
  visible,
  handleDeleteEvent,
  isDeleting,
}) => {
  const locale = getLocale();
  const timeFormat = getTimeFormat(locale);
  const navigate = useNavigate();
  return (
    <StyledEventInfoModal
      title={<Typography.Title level={2}>{event?.title}</Typography.Title>}
      onOk={handleDeleteEvent}
      okText={t("cancel_consultation")}
      destroyOnClose
      okButtonProps={{
        loading: isDeleting,
        danger: true,
        size: "large",
      }}
      cancelButtonProps={{
        size: "large",
      }}
      cancelText={t("close")}
      onCancel={onClose}
      open={visible}
    >
      <Divider />

      {event?.patient_id && event?.patient_id !== "-1" && (
        <>
          <Typography.Title style={{ marginBottom: 12 }} level={3}>
            {t("patient")}
          </Typography.Title>
          <Button
            onClick={() => navigate(`${Paths.Patients}/${event.patient_id}`)}
            icon={<LinkOutlined />}
            type="link"
            size="large"
          >
            {event?.patient_name}
          </Button>
        </>
      )}
      <StyledLabel level={4}>{t("time")}</StyledLabel>
      <Row gutter={[8, 8]}>
        <Col>
          <Typography.Title level={5}>
            {t("from")} {dayjs(event?.start_date).local().format(timeFormat)}
          </Typography.Title>
        </Col>
        <Col>
          <Typography.Title level={5}>
            {t("to")} {dayjs(event?.end_date).local().format(timeFormat)}
          </Typography.Title>
        </Col>
      </Row>
      {event?.description && (
        <>
          <Typography.Title level={4}>{t("note")}</Typography.Title>
          <Typography.Paragraph style={{ marginTop: 12 }}>
            {event?.description}
          </Typography.Paragraph>
        </>
      )}
    </StyledEventInfoModal>
  );
};

const StyledEventInfoModal = styled(Modal)`
  h2 {
    margin: 0px;
  }
  h4 {
    margin: 12px 0px 0px !important;
  }
  h5 {
    margin-top: 12px;
  }
`;

const StyledLabel = styled(Typography.Title)``;
