import { Button, Card, Dropdown, Row, Typography } from "antd";
import styled from "styled-components";
import { Note } from "../../model";
import { useState } from "react";
import { AddNoteForm } from "./AddNoteForm";
import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { t } from "i18next";
import dayjs from "dayjs";
import { getDateFormat, getLocale } from "../../utils";

interface Props {
  note: Note;
  updateNote: (note: Note, successUpdateCallBack: () => void) => void;
  deleteNote: (note: Note) => void;
  isLoadingUpdate: boolean;
}

export const NoteCard: React.FC<Props> = ({
  note,
  updateNote,
  deleteNote,
  isLoadingUpdate,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [noteValue, setNoteValue] = useState(note.note);

  const handleCancelAndReset = () => {
    setEditMode(false);
    setNoteValue(note.note);
  };

  const locale = getLocale();
  const dateFormat = getDateFormat(locale);

  const handleUpdate = () => {
    const newNote = {
      ...note,
      note: noteValue.trim(),
    };
    updateNote(newNote, () => setEditMode(false));
  };

  const isDirty = noteValue.trim() !== note.note.trim();

  const items = [
    {
      label: t("edit"),
      key: "edit",
      icon: <EditOutlined />,
      onClick: () => setEditMode(true),
    },
    {
      label: t("delete"),
      key: "delete",
      icon: <DeleteOutlined />,
      danger: true,
      onClick: () => deleteNote(note),
    },
  ];
  return (
    <StyledNoteCard
      key={note.id}
      extra={[
        <Row style={{ width: "100%" }} align="middle" justify="space-between">
          <Typography.Title level={2}>
            {dayjs(note.created_at).locale(locale).format(dateFormat)}
          </Typography.Title>
          <StyledNoteActionButtons>
            <Dropdown
              placement="bottomRight"
              trigger={["click"]}
              menu={{ items }}
            >
              <StyledMoreButton type="text" icon={<MoreOutlined />} />
            </Dropdown>
          </StyledNoteActionButtons>
        </Row>,
      ]}
    >
      {!editMode ? (
        note.note
          .split("\n")
          .filter((x) => x)
          .map((item, key) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))
      ) : (
        <>
          <AddNoteForm
            key={String(editMode)}
            onChange={(e) => setNoteValue(e)}
            value={noteValue}
          />
          <StyledFormActionButtons>
            <Button size="large" onClick={handleCancelAndReset}>
              {t("cancel")}
            </Button>
            <Button
              size="large"
              disabled={!isDirty || !Boolean(noteValue.trim().length)}
              type="primary"
              loading={isLoadingUpdate}
              onClick={handleUpdate}
            >
              {t("save")}
            </Button>
          </StyledFormActionButtons>
        </>
      )}
    </StyledNoteCard>
  );
};

const StyledNoteActionButtons = styled.div`
  button {
    background: none;
  }
`;

const StyledFormActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  button:first-of-type {
    background: none;
  }
`;

const StyledNoteCard = styled(Card)`
  h5 {
    display: flex;
    margin: 0px;
    svg {
      font-size: 20px;
      margin-right: 8px;
    }
  }
  .ant-card-extra {
    margin-inline-start: 0px !important;
    width: 100%;
  }
`;

const StyledMoreButton = styled(Button)`
  transform: translate(20px);
  svg {
    font-size: 18px;
  }
`;
