import { message, Modal, Typography } from "antd";
import styled from "styled-components";
import {
  PatientFormProvider,
  useAuth,
  usePatientFormContext,
  useWorkSpace,
} from "../../contexts";
import { isValidPatient, newPatient } from "../../utils";
import { IdcardOutlined } from "@ant-design/icons";
import { PatientForm } from "./PatientForm";
import { useMutation, useQueryClient } from "react-query";
import { createPatient as createPatientApi } from "../../api";
import { useTranslation } from "react-i18next";
import loadash from "lodash";
import { Patient } from "../../model";

interface Props {
  visible: boolean;
  onClose: () => void;
}
export const NewPatientModal: React.FC<Props> = (props) => (
  <PatientFormProvider initialValues={{ ...newPatient() } as any}>
    <NewPatientModalContent {...props} />
  </PatientFormProvider>
);

export const NewPatientModalContent: React.FC<Props> = ({
  visible,
  onClose,
}) => {
  const { currentUser } = useAuth();
  const { currentWorkspace } = useWorkSpace();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { formValues, updateValues } = usePatientFormContext();
  const [messageApi, contextHolder] = message.useMessage();

  const { mutate: createPatient, isLoading } = useMutation(createPatientApi, {
    onError: (e) => console.log(e),
    onSuccess: (e) => {
      queryClient.invalidateQueries("patients");
      queryClient.invalidateQueries("patients-stats");
      handleCloseModal();
      messageApi.open({
        type: "success",
        content: t("create_success_message"),
      });
    },
  });

  const handleCloseModal = () => {
    onClose();
    updateValues(newPatient());
  };

  const handleSaveClick = () => {
    const cleanedForm = loadash(formValues)
      .omitBy((item) => {
        if (typeof item === "string") {
          return loadash.isEmpty(item.trim());
        } else {
          return (
            loadash.isEmpty(item.code.trim()) ||
            loadash.isEmpty(item.number.trim())
          );
        }
      })
      .value();

    createPatient({
      ...cleanedForm,
      created_by: currentUser?.uid!,
      owner_id: currentUser?.uid!,
      workspace_id: currentWorkspace?.workspace_id!,
      template_id: currentWorkspace?.template_id!,
    } as Patient);
  };

  isValidPatient(formValues);

  return (
    <StyledNewPatientModal>
      {contextHolder}
      <StyledModal
        maskClosable={false}
        destroyOnClose
        cancelText={t("cancel")}
        okText={t("create")}
        okButtonProps={{
          size: "large",
          disabled: isValidPatient(formValues),
          loading: isLoading,
        }}
        cancelButtonProps={{ size: "large" }}
        title={
          <StyledModalTitle level={3}>
            <IdcardOutlined />
            {t("new_patient")}
          </StyledModalTitle>
        }
        open={visible}
        onOk={handleSaveClick}
        onCancel={() => {
          onClose();
          updateValues(newPatient());
        }}
      >
        <PatientForm />
      </StyledModal>
    </StyledNewPatientModal>
  );
};

const StyledNewPatientModal = styled.div``;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-bottom: 0px;
  }
  .ant-modal-footer {
    padding: 12px 16px;
    button {
      min-width: 110px;
      border-radius: 32px;
    }
  }
`;

export const StyledModalTitle = styled(Typography.Title)`
  margin: 0px !important;
  margin-bottom: 24px !important;
  display: flex;
  align-items: center;
  svg {
    font-size: 28px;
    margin-right: 12px;
  }
`;
