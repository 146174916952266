import { Drawer, Menu, MenuProps, Row, theme } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SiderLogo } from "../design";
import { dashboardItems } from "../router";
import { WorkspaceSelector } from "./selectors";
import { UserMenu } from "./UserMenu";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const MobileDrawer: React.FC<Props> = ({ open, onClose }) => {
  const {
    token: { colorBgElevated },
  } = theme.useToken();
  const items: MenuProps["items"] = dashboardItems.map((path, index) => {
    return {
      key: path.path,
      icon: path.icon,
      label: path.name,
      title: path.name,
    };
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <StyledMobileDrawer
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      width="50%"
      footer={<UserMenu clickAction={onClose} />}
    >
      <Row style={{ marginBottom: 56 }} justify="center">
        <SiderLogo />
      </Row>
      <StyledWorkspaceSelectorContainer>
        <WorkspaceSelector />
      </StyledWorkspaceSelectorContainer>
      <Menu
        style={{
          backgroundColor: colorBgElevated,
        }}
        mode="vertical"
        selectedKeys={[`/${pathname.split("/")[1]}`]}
        items={items}
        onClick={(item) => {
          navigate(item.key);
          onClose();
        }}
      />
    </StyledMobileDrawer>
  );
};

const StyledWorkspaceSelectorContainer = styled.div`
  text-align: center;
  margin-bottom: 24px;
`;

const StyledMobileDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 12px;
  }
  .ant-menu-vertical {
    border-inline-end: none !important;
  }
  .ant-drawer-footer {
    text-align: center;
    padding: 12px 0px 48px 0px;
    border-top: none;
  }
`;
