import { Button, Result } from "antd";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <StyledNotFound>
      <Result
        status="404"
        title="404"
        subTitle={t("error_404_message")}
        extra={
          <Button size="large" onClick={() => navigate(-1)} type="primary">
            {t("go_back")}
          </Button>
        }
      />
    </StyledNotFound>
  );
};

const StyledNotFound = styled.div``;
