import { useState } from "react";

export const useBoolean = (
  initial?: boolean
): [boolean, () => void, () => void] => {
  const [boolean, setBoolean] = useState(initial || false);
  const setBooleanTrue = () => setBoolean(true);
  const setBooleanFalse = () => setBoolean(false);
  return [boolean, setBooleanTrue, setBooleanFalse];
};
