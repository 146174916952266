export const SiderLogo = () => (
  <svg
    width="57"
    height="49"
    viewBox="0 0 57 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25842 24.0135C1.67323 24.0135 1.23434 23.9122 0.941746 23.7096C0.649151 23.5296 0.457839 23.282 0.36781 22.9669C0.27778 22.6518 0.232766 22.3142 0.232766 21.954V2.44021C0.232766 2.08009 0.266527 1.75374 0.334048 1.46114C0.424078 1.14604 0.61539 0.898458 0.907985 0.718399C1.20058 0.515834 1.66198 0.414551 2.29218 0.414551L8.63925 0.448313C10.2823 0.448313 11.7903 0.729654 13.1632 1.29234C14.5587 1.85502 15.7628 2.65403 16.7756 3.68936C17.7885 4.7247 18.565 5.9626 19.1051 7.40307C19.6678 8.82103 19.9492 10.3853 19.9492 12.0958C19.9492 13.8514 19.6678 15.4607 19.1051 16.9237C18.5425 18.3866 17.7547 19.647 16.7419 20.7049C15.729 21.7627 14.5249 22.5842 13.1295 23.1694C11.7565 23.7321 10.226 24.0135 8.53796 24.0135H2.25842ZM4.31784 19.9284H8.63925C10.0797 19.9284 11.3401 19.6133 12.4205 18.9831C13.5008 18.3304 14.3336 17.4301 14.9188 16.2822C15.5265 15.1343 15.8303 13.7951 15.8303 12.2646C15.8303 10.7116 15.5265 9.36121 14.9188 8.21333C14.3336 7.04295 13.4896 6.14266 12.3867 5.51246C11.3064 4.85974 10.046 4.53339 8.60549 4.53339H4.31784V19.9284Z"
      fill="#165DD7"
    />
    <path
      d="M30.9109 24.2498C29.8306 24.2498 28.7727 24.0472 27.7374 23.6421C26.7246 23.2145 25.8018 22.618 24.969 21.8528C24.1587 21.0875 23.506 20.1872 23.0109 19.1519C22.5382 18.1165 22.3019 16.9799 22.3019 15.742C22.3019 14.5491 22.527 13.435 22.9771 12.3997C23.4273 11.3644 24.0462 10.4528 24.834 9.66505C25.6217 8.8773 26.522 8.2696 27.5348 7.84196C28.5702 7.39182 29.673 7.16674 30.8434 7.16674C32.4189 7.16674 33.8481 7.54937 35.1311 8.31462C36.414 9.05736 37.4381 10.0702 38.2033 11.3531C38.9685 12.636 39.3512 14.0877 39.3512 15.7083C39.3512 16.9912 39.1148 18.1616 38.6422 19.2194C38.1695 20.2547 37.5281 21.155 36.7178 21.9203C35.9076 22.663 34.996 23.237 33.9832 23.6421C32.9929 24.0472 31.9688 24.2498 30.9109 24.2498ZM30.8434 20.266C31.5862 20.266 32.2951 20.0859 32.9704 19.7258C33.6681 19.3432 34.2308 18.803 34.6584 18.1053C35.1085 17.4076 35.3336 16.5861 35.3336 15.6407C35.3336 14.763 35.1311 13.9865 34.7259 13.3112C34.3433 12.636 33.8144 12.1071 33.1392 11.7245C32.4639 11.3418 31.6874 11.1505 30.8096 11.1505C29.9769 11.1505 29.2229 11.3531 28.5477 11.7582C27.8724 12.1409 27.3323 12.681 26.9271 13.3788C26.5445 14.054 26.3532 14.8192 26.3532 15.6745C26.3532 16.6198 26.567 17.4413 26.9947 18.1391C27.4223 18.8143 27.9737 19.3432 28.6489 19.7258C29.3467 20.0859 30.0782 20.266 30.8434 20.266Z"
      fill="#165DD7"
    />
    <path
      d="M50.0397 24.1147C48.9593 24.1147 47.9015 23.9347 46.8662 23.5746C45.8533 23.1919 44.9305 22.6405 44.0978 21.9203C43.2875 21.2 42.6348 20.3223 42.1396 19.2869C41.667 18.2291 41.4306 17.0362 41.4306 15.7083C41.4306 14.3803 41.667 13.1987 42.1396 12.1634C42.6348 11.128 43.2875 10.2502 44.0978 9.53001C44.908 8.80978 45.8196 8.25835 46.8324 7.87572C47.8452 7.4931 48.8918 7.30179 49.9722 7.30179C50.7599 7.30179 51.5027 7.41432 52.2004 7.6394C52.9206 7.86447 53.5396 8.13456 54.0572 8.44966C54.5749 8.76476 54.9463 9.07986 55.1713 9.39497C55.4639 9.66505 55.7115 9.92389 55.9141 10.1715C56.1167 10.419 56.1842 10.7229 56.1167 11.083C56.0491 11.3081 55.9479 11.5219 55.8128 11.7245C55.6778 11.9045 55.509 12.0958 55.3064 12.2984C54.7662 12.9061 54.2373 13.1312 53.7196 12.9736C53.427 12.8161 53.1344 12.6473 52.8418 12.4672C52.5493 12.2872 52.2454 12.1183 51.9303 11.9608C51.6377 11.7807 51.3339 11.6344 51.0188 11.5219C50.7036 11.4094 50.3548 11.3531 49.9722 11.3531C49.1169 11.3531 48.3516 11.5444 47.6764 11.927C47.0237 12.2872 46.506 12.7936 46.1234 13.4463C45.7633 14.099 45.5832 14.853 45.5832 15.7083C45.5832 16.5635 45.7633 17.3288 46.1234 18.004C46.506 18.6567 47.0237 19.1744 47.6764 19.557C48.3291 19.9171 49.0719 20.0972 49.9046 20.0972C50.3098 20.0972 50.6924 20.0522 51.0525 19.9621C51.4351 19.8496 51.7727 19.7146 52.0653 19.557C52.3579 19.377 52.5943 19.1969 52.7743 19.0168C53.0219 18.8593 53.247 18.713 53.4495 18.5779C53.6521 18.4429 53.8659 18.3754 54.091 18.3754C54.3611 18.3754 54.6199 18.4767 54.8675 18.6792C55.1376 18.8593 55.4302 19.1519 55.7453 19.557C56.0379 19.8946 56.1954 20.221 56.2179 20.5361C56.263 20.8512 56.1954 21.155 56.0154 21.4476C55.8578 21.7402 55.5877 22.0103 55.2051 22.2579C54.935 22.528 54.5299 22.8093 53.9897 23.1019C53.472 23.372 52.8643 23.6083 52.1666 23.8109C51.4914 24.0135 50.7824 24.1147 50.0397 24.1147Z"
      fill="#165DD7"
    />
    <path
      d="M1.81717 48.4709C1.35131 48.4709 1.00191 48.3993 0.76898 48.2559C0.536048 48.1126 0.383747 47.9155 0.312076 47.6646C0.258322 47.4138 0.231445 47.1361 0.231445 46.8315V31.3236C0.231445 31.0369 0.258322 30.7771 0.312076 30.5441C0.383747 30.2933 0.536048 30.0962 0.76898 29.9529C1.00191 29.7916 1.36923 29.711 1.87093 29.711C2.2472 29.711 2.52493 29.7378 2.70411 29.7916C2.9012 29.8274 3.0535 29.8901 3.16101 29.9797C3.26852 30.0514 3.37602 30.15 3.48353 30.2754L10.0415 39.4403H9.42329L16.0081 30.2485C16.1335 30.1052 16.2589 29.9977 16.3844 29.926C16.5098 29.8543 16.6711 29.8006 16.8682 29.7647C17.0652 29.7289 17.3251 29.711 17.6476 29.711C18.1314 29.711 18.4897 29.7916 18.7226 29.9529C18.9556 30.0962 19.0989 30.2933 19.1527 30.5441C19.2243 30.795 19.2602 31.0727 19.2602 31.3773V46.8852C19.2602 47.154 19.2243 47.4138 19.1527 47.6646C19.0989 47.9155 18.9556 48.1126 18.7226 48.2559C18.4897 48.3993 18.1224 48.4709 17.6207 48.4709C17.1369 48.4709 16.7786 48.3903 16.5456 48.229C16.3127 48.0857 16.1604 47.8886 16.0887 47.6378C16.035 47.3869 16.0081 47.1181 16.0081 46.8315V35.3013L11.1165 42.3699C11.0628 42.4416 10.9821 42.5401 10.8746 42.6656C10.7671 42.7731 10.6238 42.8716 10.4446 42.9612C10.2654 43.0508 10.0235 43.0956 9.71894 43.0956C9.43225 43.0956 9.19932 43.0508 9.02014 42.9612C8.84096 42.8716 8.69762 42.7731 8.59011 42.6656C8.50052 42.558 8.41093 42.4505 8.32135 42.343L3.45666 35.3013V46.8852C3.45666 47.154 3.42082 47.4138 3.34915 47.6646C3.29539 47.9155 3.15205 48.1126 2.91912 48.2559C2.68619 48.3993 2.31887 48.4709 1.81717 48.4709Z"
      fill="#165DD7"
    />
    <path
      d="M23.8914 48.4978C23.4076 48.4978 23.0492 48.4172 22.8163 48.2559C22.5834 48.1126 22.44 47.9244 22.3863 47.6915C22.3325 47.4407 22.3057 47.1629 22.3057 46.8583V36.8602C22.3057 36.5735 22.3325 36.3137 22.3863 36.0808C22.458 35.8299 22.6013 35.6328 22.8163 35.4895C23.0492 35.3461 23.4166 35.2745 23.9183 35.2745C24.42 35.2745 24.7783 35.3461 24.9933 35.4895C25.2263 35.6328 25.3696 35.8299 25.4234 36.0808C25.4771 36.3316 25.504 36.6093 25.504 36.9139V46.8852C25.504 47.1898 25.4771 47.4675 25.4234 47.7184C25.3696 47.9513 25.2263 48.1395 24.9933 48.2828C24.7783 48.4261 24.411 48.4978 23.8914 48.4978ZM23.8914 32.6674C23.3718 32.6674 22.9865 32.5957 22.7357 32.4524C22.5027 32.2911 22.3504 32.0761 22.2788 31.8074C22.225 31.5386 22.1981 31.2429 22.1981 30.9204C22.1981 30.58 22.225 30.2843 22.2788 30.0335C22.3504 29.7826 22.5117 29.5855 22.7626 29.4422C23.0134 29.2809 23.3986 29.2003 23.9183 29.2003C24.4558 29.2003 24.841 29.2809 25.074 29.4422C25.3069 29.6035 25.4592 29.8185 25.5309 30.0872C25.6025 30.3381 25.6384 30.6337 25.6384 30.9742C25.6384 31.2788 25.6025 31.5655 25.5309 31.8342C25.4592 32.103 25.3069 32.3091 25.074 32.4524C24.841 32.5957 24.4468 32.6674 23.8914 32.6674Z"
      fill="#165DD7"
    />
    <path
      d="M30.03 48.4709C29.5462 48.4709 29.1878 48.3903 28.9549 48.229C28.7399 48.0857 28.6055 47.8976 28.5518 47.6646C28.498 47.4138 28.4711 47.1361 28.4711 46.8315V36.7796C28.4711 36.4929 28.498 36.2331 28.5518 36.0001C28.6055 35.7672 28.7489 35.588 28.9818 35.4626C29.2147 35.3192 29.5731 35.2476 30.0569 35.2476C30.4869 35.2476 30.8094 35.3013 31.0244 35.4088C31.2394 35.5163 31.3828 35.6507 31.4545 35.812C31.5261 35.9732 31.562 36.1345 31.562 36.2958C31.5799 36.457 31.5978 36.5825 31.6157 36.672L31.5082 37.5321C31.6157 37.2454 31.7949 36.9677 32.0457 36.6989C32.2966 36.4302 32.6012 36.1883 32.9596 35.9732C33.3179 35.7403 33.7031 35.5611 34.1153 35.4357C34.5453 35.2924 34.9843 35.2207 35.4322 35.2207C36.2743 35.2207 37.0269 35.373 37.6899 35.6776C38.3528 35.9822 38.9172 36.4122 39.3831 36.9677C39.8669 37.5231 40.2342 38.1861 40.485 38.9566C40.7359 39.7091 40.8613 40.5602 40.8613 41.5099V46.8583C40.8613 47.1629 40.8255 47.4407 40.7538 47.6915C40.7001 47.9244 40.5657 48.1126 40.3507 48.2559C40.1356 48.3993 39.7683 48.4709 39.2487 48.4709C38.747 48.4709 38.3797 48.3903 38.1468 48.229C37.9138 48.0857 37.7705 47.8976 37.7167 47.6646C37.663 47.4138 37.6361 47.1361 37.6361 46.8315V41.483C37.6361 40.8379 37.5196 40.2825 37.2867 39.8166C37.0717 39.3508 36.7492 38.9924 36.3191 38.7416C35.907 38.4907 35.3785 38.3653 34.7334 38.3653C34.1063 38.3653 33.5598 38.4997 33.0939 38.7684C32.6281 39.0193 32.2697 39.3776 32.0189 39.8435C31.768 40.3094 31.6426 40.8559 31.6426 41.483V46.8583C31.6426 47.1629 31.6157 47.4407 31.562 47.6915C31.5082 47.9244 31.3649 48.1126 31.1319 48.2559C30.9169 48.3993 30.5496 48.4709 30.03 48.4709Z"
      fill="#165DD7"
    />
    <path
      d="M49.1816 48.4709C48.3573 48.4709 47.5779 48.3007 46.8433 47.9603C46.1086 47.6198 45.4636 47.154 44.9082 46.5627C44.3527 45.9535 43.9137 45.2457 43.5912 44.4394C43.2687 43.6152 43.1074 42.7372 43.1074 41.8055C43.1074 40.9096 43.2687 40.0675 43.5912 39.2791C43.9137 38.4728 44.3527 37.774 44.9082 37.1827C45.4815 36.5735 46.1355 36.0987 46.8702 35.7582C47.6048 35.4178 48.3753 35.2476 49.1816 35.2476C50.0416 35.2476 50.8121 35.4178 51.493 35.7582C52.1738 36.0987 52.7472 36.5735 53.2131 37.1827C53.6969 37.774 54.0642 38.4728 54.315 39.2791C54.5659 40.0675 54.7002 40.9186 54.7182 41.8324C54.7182 42.7462 54.5927 43.6062 54.3419 44.4125C54.109 45.2009 53.7506 45.8997 53.2668 46.5089C52.801 47.1181 52.2186 47.6019 51.5198 47.9603C50.839 48.3007 50.0595 48.4709 49.1816 48.4709ZM49.6116 45.3264C50.2387 45.3264 50.7942 45.183 51.2779 44.8963C51.7796 44.5917 52.1738 44.1796 52.4605 43.66C52.7651 43.1404 52.9174 42.5491 52.9174 41.8861C52.9174 41.2053 52.7651 40.605 52.4605 40.0854C52.1738 39.5658 51.7796 39.1537 51.2779 38.8491C50.7942 38.5445 50.2387 38.3922 49.6116 38.3922C48.9845 38.3922 48.42 38.5445 47.9184 38.8491C47.4167 39.1357 47.0135 39.5479 46.7089 40.0854C46.4222 40.605 46.2789 41.2053 46.2789 41.8861C46.2789 42.5491 46.4222 43.1404 46.7089 43.66C47.0135 44.1796 47.4167 44.5917 47.9184 44.8963C48.438 45.183 49.0024 45.3264 49.6116 45.3264ZM54.5838 48.4709C54.0821 48.4709 53.7148 48.3903 53.4818 48.229C53.2489 48.0499 53.0966 47.7721 53.0249 47.3959V30.8398C53.0249 30.5352 53.0428 30.2664 53.0787 30.0335C53.1324 29.7826 53.2668 29.5855 53.4818 29.4422C53.7148 29.2809 54.0731 29.2003 54.5569 29.2003C55.0765 29.2003 55.4438 29.272 55.6589 29.4153C55.8739 29.5587 56.0082 29.7558 56.062 30.0066C56.1337 30.2395 56.1695 30.5083 56.1695 30.8129V46.8315C56.1695 47.1361 56.1426 47.4138 56.0889 47.6646C56.0351 47.9155 55.8918 48.1126 55.6589 48.2559C55.4438 48.3993 55.0855 48.4709 54.5838 48.4709Z"
      fill="#165DD7"
    />
  </svg>
);
