import { Card, Col, Row, Skeleton } from "antd";
import styled from "styled-components";

export const NotesLoader: React.FC = () => {
  return (
    <StyledNotesLoader gutter={[24, 24]}>
      <Col span={24}>
        <Skeleton title={false} paragraph={{ rows: 1, width: "100%" }} />
      </Col>
      {Array.from({ length: 6 }).map((_, i) => (
        <Col key={i} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Card>
            <Skeleton />
          </Card>
        </Col>
      ))}
    </StyledNotesLoader>
  );
};

const StyledNotesLoader = styled(Row)``;
