import React, { useContext, useState } from "react";
import { MobileDrawer } from "../components";
interface ContextProps {
  isOpen: boolean;
  openMobileDrawer: () => void;
  onClose: () => void;
}

const MobileDrawerContext = React.createContext<ContextProps>(
  {} as ContextProps
);

export const useMobileDrawer = () => useContext(MobileDrawerContext);

export const MobileDrawerProvider = ({ children }: React.PropsWithChildren) => {
  const [isOpen, setOpenDrawer] = useState<boolean>(false);

  const openMobileDrawer = () => setOpenDrawer(true);

  const onClose = () => setOpenDrawer(false);

  const value: ContextProps = {
    isOpen,
    openMobileDrawer,
    onClose,
  };

  return (
    <MobileDrawerContext.Provider value={value}>
      <MobileDrawer onClose={onClose} open={isOpen} />
      {children}
    </MobileDrawerContext.Provider>
  );
};
