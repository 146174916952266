export const useDarkTheme = () => {
  const darkTheme = localStorage.getItem("darkTheme") === "true";
  const activateDarkTheme = () => {
    localStorage.setItem("darkTheme", "true");
    window.location.reload();
  };
  const activateLightTheme = () => {
    localStorage.setItem("darkTheme", "false");
    window.location.reload();
  };
  return { darkTheme, activateDarkTheme, activateLightTheme };
};
