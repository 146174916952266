import React, { useContext, useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  UserCredential,
  User,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from "@firebase/auth";
import { auth } from "../api";
import { FirebaseError } from "@firebase/app";
import { message } from "antd";
import { AuthErrorCode, generateError } from "../utils";
import { useQuery, useQueryClient } from "react-query";
import { AuthWorkspaceClaim } from "../model";

interface AuthProps {
  currentUser: User | null;
  login: (email: string, password: string) => Promise<void | UserCredential>;
  rememberMeLogin: (email: string, password: string) => Promise<UserCredential>;
  failed: boolean;
  loginFailSetter: (failed: boolean) => void;
  logout: () => Promise<void>;
  isLogginIn: boolean;
  invalidateUserData: () => void;
  claims?: AuthWorkspaceClaim[];
  accessToken?: string;
}

const AuthContext = React.createContext<AuthProps>({} as AuthProps);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [, setDisplayName] = useState<string>(currentUser?.displayName || "");
  const queryClient = useQueryClient();

  const { data } = useQuery(
    ["user-claims", currentUser?.uid],
    () => currentUser?.getIdTokenResult(),
    { enabled: Boolean(currentUser?.uid) }
  );

  const claims = data?.claims.workspaces as AuthWorkspaceClaim[];
  const accessToken = data?.token;

  const [loading, setLoading] = useState(true);
  const [failedLogin, setFailedLogin] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLogginIn, setIsLogginIn] = useState(false);

  const error = (code: AuthErrorCode) => {
    messageApi.open({
      type: "error",
      content: generateError(code),
    });
  };

  const invalidateUserData = async () => {
    return await currentUser?.reload().then(() => {
      setDisplayName(auth.currentUser?.displayName || "");
    });
  };

  const login = async (email: string, password: string) => {
    return setPersistence(auth, browserSessionPersistence)
      .then(() => {
        setIsLogginIn(true);
        return signInWithEmailAndPassword(auth, email, password);
      })
      .catch((reason: FirebaseError) => error(reason.code as AuthErrorCode))
      .finally(() => setIsLogginIn(false));
  };

  const rememberMeLogin = async (email: string, password: string) => {
    return setPersistence(auth, browserLocalPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password);
      })
      .finally(() => setIsLogginIn(false));
  };

  const logout = async () => auth.signOut();

  const loginFailSetter = (failed: boolean) => setFailedLogin(failed);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
      setFailedLogin(false);
    });
    return () => {
      unsubscribe();
    };
  }, [queryClient]);

  useEffect(() => {
    if (!currentUser) {
      queryClient.removeQueries();
    }
  });

  const value = {
    currentUser,
    login,
    failed: failedLogin,
    loginFailSetter,
    logout,
    rememberMeLogin,
    isLogginIn,
    invalidateUserData,
    claims,
    accessToken,
  };

  return (
    <AuthContext.Provider value={value}>
      {contextHolder}
      {!loading && children}
    </AuthContext.Provider>
  );
};
