import axios from "axios";
import { getConfig } from "./config";
import { auth } from "./firebase";

export const apiClient = axios.create({
  baseURL: getConfig().url,
});

apiClient.interceptors.request.use(async (config) => {
  const token = await auth.currentUser?.getIdToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});
