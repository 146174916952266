import { AutoComplete, Row, Skeleton } from "antd";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import styled from "styled-components";
import { browsePatients } from "../../api";
import { useWorkSpace } from "../../contexts";
import { Patient } from "../../model";

interface Props {
  onChange: (patient: Patient) => void;
}

export const PatientsSelector: React.FC<Props> = ({ onChange }) => {
  const { currentWorkspace } = useWorkSpace();
  const [search, setSearch] = useState<string>("");
  const [searchApi, setSearchApi] = useState<string>("");
  const { t } = useTranslation();

  const debounceSearch = useRef(debounce(setSearchApi, 300));

  const { data, isLoading } = useQuery(
    ["patients-search", searchApi, currentWorkspace?.workspace_id],
    () =>
      browsePatients(currentWorkspace?.workspace_id!, {
        search: search.trim(),
      }),
    {
      enabled: Boolean(currentWorkspace?.workspace_id),
    }
  );

  useEffect(() => {
    const current = debounceSearch.current;
    current(search);
    return () => current.cancel();
  });

  const options = data?.data?.map((patient) => ({
    label: `${patient.first_name} ${patient.last_name}`,
    value: patient.patient_id,
  }));

  return (
    <StyledPatientsSearch>
      <AutoComplete
        placeholder={t("search_placeholder")}
        style={{ width: "100%" }}
        options={options}
        onSelect={(patient_id, option) => {
          setSearch(option.label);
          onChange(data?.data?.find((p) => p.patient_id === patient_id)!);
        }}
        searchValue={search}
        value={search}
        onChange={(value) => setSearch(value)}
        notFoundContent={
          isLoading ? (
            <Row>
              <Skeleton
                active
                paragraph={{ rows: 0 }}
                title={{ style: { marginTop: 16, width: "100%" } }}
              />
            </Row>
          ) : (
            t("no_result")
          )
        }
      />
    </StyledPatientsSearch>
  );
};

const StyledPatientsSearch = styled.div`
  h3 {
    margin: 0px;
  }
`;
