import { Spin } from "antd";
import styled from "styled-components";

interface Props {}

export const LoadingCell: React.FC<Props> = () => {
  return (
    <StyledLoadingCell>
      <Spin size="small" />
    </StyledLoadingCell>
  );
};

const StyledLoadingCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
