import { createBrowserRouter, useNavigate } from "react-router-dom";
import { Root } from "../Root";
import {
  CalendarScreen,
  Dashboard,
  LoginScreen,
  PatientScreen,
  PatientsScreen,
  ProfileScreen,
} from "../screens";
import { AppstoreFilled, CalendarFilled } from "@ant-design/icons";
import { SettingsScreen } from "../screens";
import { FaUserAlt } from "react-icons/fa";
import { t } from "i18next";
import { Button, Layout, Result } from "antd";
import { Suspense } from "react";

export enum Paths {
  Dashboard = "/",
  Patients = "/patients",
  Login = "/login",
  Profile = "/profile",
  Settings = "/settings",
  Calendar = "/calendar",
}

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle={t("error_404_message")}
      extra={
        <Button size="large" onClick={() => navigate(-1)} type="primary">
          {t("go_back")}
        </Button>
      }
    />
  );
};

export const dashboardItems = [
  {
    path: Paths.Dashboard,
    name: "Dashboard",
    icon: <AppstoreFilled />,
  },
  {
    path: Paths.Patients,
    name: "Patients",
    icon: <FaUserAlt />,
  },
  {
    path: Paths.Calendar,
    name: "Calendar",
    icon: <CalendarFilled />,
  },
];

const routes = [
  {
    path: Paths.Dashboard,
    element: (
      <Suspense>
        <Dashboard title={`DocMind - ${t("dashboard")}`} />
      </Suspense>
    ),
  },
  {
    path: Paths.Patients,
    element: (
      <Suspense>
        <PatientsScreen title={`DocMind - ${t("patients")}`} />
      </Suspense>
    ),
  },
  {
    path: `${Paths.Patients}/:id`,
    element: (
      <Suspense>
        <PatientScreen />
      </Suspense>
    ),
  },
  {
    path: `${Paths.Profile}`,
    element: (
      <Suspense>
        <ProfileScreen title={`DocMind - ${t("profile")}`} />
      </Suspense>
    ),
  },
  {
    path: `${Paths.Settings}`,
    element: (
      <Suspense>
        <SettingsScreen title={`DocMind - ${t("settings")}`} />
      </Suspense>
    ),
  },
  {
    path: `${Paths.Calendar}`,
    element: (
      <Suspense>
        <CalendarScreen title={`DocMind - ${t("calendar")}`} />
      </Suspense>
    ),
  },
];

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: routes,
    errorElement: (
      <Layout style={{ height: "100vh" }}>
        <Layout.Content>
          <NotFound />
        </Layout.Content>
      </Layout>
    ),
  },
  {
    path: Paths.Login,
    element: (
      <Suspense>
        <LoginScreen />
      </Suspense>
    ),
  },
]);
