import { initializeApp } from "@firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";
import { getAuth } from "@firebase/auth";

//Initialize the firebase app
export const app = initializeApp({
  apiKey: "AIzaSyCuBfvuWuoe8Df5PODxhxL6KjeJuQabbRA",
  authDomain: "docmind-7a3fc.firebaseapp.com",
  projectId: "docmind-7a3fc",
  storageBucket: "docmind-7a3fc.appspot.com",
  messagingSenderId: "760474244809",
  appId: "1:760474244809:web:1bbff2279dae0f40c2df18",
  measurementId: "G-RYXCXPL8K0",
});

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
