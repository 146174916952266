import { Card, Col, Row, theme, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { Event } from "../../model";
import { t } from "i18next";
import { getLocale, getTimeFormat } from "../../utils";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface Props {
  event: Event;
  onTarget: (patient: Event) => void;
}

export const EventCard: React.FC<Props> = ({ event, onTarget }) => {
  const locale = getLocale();
  const timeFormat = getTimeFormat(locale);
  const isPast = dayjs().isAfter(dayjs(event.end_date));
  const isFuture = dayjs().isBefore(dayjs(event.start_date));

  const {
    token: { colorSuccess, colorPrimary, colorBorder },
  } = theme.useToken();

  const color = isPast ? colorBorder : isFuture ? colorPrimary : colorSuccess;

  return (
    <StyledEventCard $color={color} onClick={() => onTarget(event)}>
      <Row align="middle" wrap={false} justify="space-between">
        <Col span={12}>
          <Typography.Title ellipsis style={{ margin: 0 }} level={5}>
            {event?.title}
          </Typography.Title>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Typography.Text>
            {t("from")} {dayjs(event?.start_date).local().format(timeFormat)}
          </Typography.Text>
          <br />
          <Typography.Text style={{ marginTop: 4 }}>
            {t("to")} {dayjs(event?.end_date).local().format(timeFormat)}
          </Typography.Text>
        </Col>
      </Row>
    </StyledEventCard>
  );
};

const StyledEventCard = styled(Card)<{ $color: string }>`
  border-left: 10px solid ${({ $color }) => $color};
  :hover {
    opacity: 0.8;
    cursor: pointer;
  }
  .ant-card-body {
    padding: 16px;
  }
  .ant-card-actions {
    li {
      margin: 8px;
    }
  }
  h5 {
    margin: 0px;
  }
`;
