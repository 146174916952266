export const en = {
  translation: {
    language: "Language",
    time: "Time",

    today: "Today",

    years: "YRS",

    password: "Password",
    remember_me: "Remember me",

    not_available_on_mobile: "This page is not available on mobile yet",

    files: "Files",
    file: "File",

    login: "Login",
    upload: "Upload",
    save: "Save",
    create: "Create",
    cancel: "Cancel",
    edit: "Edit",
    delete: "Delete",
    close: "Close",
    see_more: "See more",
    add_new: "Add new",
    add_title: "Add a title",
    switch_to_dark_theme: "Switch to Dark theme",
    switch_to_light_theme: "Switch to Light theme",

    added_on: "Added on",
    last_edited_on: "Last edited on",
    date_format: "AAAA-MM-JJ",

    search_placeholder: "Search..",
    no_result: "No result",

    no: "No",
    yes: "Yes",

    from: "From",
    to: "to",

    dashboard: "Dashboard",
    profile: "Profile",
    settings: "Settings",
    calendar: "Calendar",

    logout: "Logout",
    back: "Back",

    good_day: "Good Day",
    have_a_nice_day: "Have a nice",
    sign_in: "Sign in",

    patients: "Patients",
    patient: "Patient",
    new_patient: "New patient",

    name: "Name",
    first_name: "First name",
    last_name: "Last name",
    gender: "Gender",
    birthdate: "Birthdate",
    phone: "Phone",
    phone_code: "Code",
    address: "Address",
    male: "Male",
    female: "Female",
    email: "E-mail",

    email_error: "Should be an email format, eg: email@domain.com",

    date_format_placeholder: "AAAA-MM-DD",

    delete_modal_title: "Delete {{entity}}",
    are_you_sure_delete: "Are you sure to delete {{name}}?",
    delete_success_message: "Successfully deleted",
    edit_success_message: "Successfully edited",
    create_success_message: "Successfully created",

    loading_workspaces: "Loading Workspace informations..",
    loading_calendar: "Loading Calendar informations..",

    patient_collection_observations: "Observations",
    patient_collection_biology: "Biology",
    patient_collection_endoscopy: "Endoscopy",
    patient_collection_proctology: "Proctology",

    note: "Note",
    this_note: "this note",
    new_note: "New note..",

    file_dragger_placeholder: "Click or drag file to this area to upload",
    file_dragger_rules: "Only images and PDF files are accepted",

    go_back: "Go back",
    go_dashboard: "Back home",

    error_404_message: "Sorry, the page you visited does not exist.",
    error_500_message: "Sorry, an error occured. Please try again later",
    network_error: "Network Error, please try again later",
    auth_login_error:
      "Invalid login credentials. Please check your email and password and try again.",

    email_input_error: "Please enter an email address",
    password_input_error:
      "Password field cannot be empty. Please enter a password to continue.",

    choose_file_name: "Choose a name for your file",

    consultation: "Consultation",
    consultations: "Consultations",
    next_consultations: "Today's consultations",
    new_consultation: "New consultation",
    cancel_consultation: "Cancel consultation",
    are_you_sure_cancel_consultation:
      "Are you sure to cancel this consultation?",
    link_patient: "Link an existing patient?",
    no_consultation_today: "No upcoming consultations today!",
    no_consultation_planned: "No consultation planned",

    end_time_error: "From hour cannot be superior to end hour",
    patient_name_error:
      "Make sure you enter a name and a last name for the new patient and try again.",

    new_patient_widget_placeholder: "Added this month",
    new_patient_widget_title: "New patients",
    total_patients_placeholder: "You have",
    total_patients: "TOTAL PATIENTS",

    weekly_consultations_widget_placeholder: "Planned this week",
  },
};
