import { Avatar, Dropdown, MenuProps } from "antd";
import styled from "styled-components";
import { useAuth } from "../contexts";
import {
  LogoutOutlined,
  UserOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Paths } from "../router";

interface Props {
  clickAction?: () => void;
}

export const UserMenu: React.FC<Props> = ({ clickAction }) => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const items: MenuProps["items"] = [
    {
      label: t("profile"),
      key: "profile",
      icon: <UserOutlined />,
      title: "",
      onClick: () => {
        navigate(Paths.Profile);
        clickAction?.();
      },
    },
    {
      label: t("settings"),
      key: "settings",
      onClick: () => {
        navigate(Paths.Settings);
        clickAction?.();
      },
      icon: <SettingOutlined />,
      title: "",
    },
    {
      label: t("logout"),
      key: "logout",
      danger: true,
      icon: <LogoutOutlined />,
      onClick: () => {
        logout();
        clickAction?.();
      },
      title: "",
    },
  ];

  return (
    <StyledUserMenu>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <Avatar
          src={currentUser?.photoURL}
          size={56}
          onClick={(e) => e?.preventDefault()}
        >
          {getInitials(currentUser!.displayName)}
        </Avatar>
      </Dropdown>
    </StyledUserMenu>
  );
};

export const getInitials = (displayName: string | null) => {
  if (!displayName) {
    return;
  }
  const nameArray = displayName.split(" ");
  let stringName = "";
  nameArray.forEach(
    (initial) => (stringName += `${initial.charAt(0).toUpperCase()}`)
  );
  return stringName;
};

const StyledUserMenu = styled.div`
  :hover {
    cursor: pointer;
  }
`;
