import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { browseWorkspaces, readTemplate } from "../api";
import { Template, Workspace } from "../model";
import { useAuth } from "./AuthContext";

interface WorkspaceProps {
  currentWorkspace: Workspace | null;
  template?: Template;
  workspaces: Workspace[];
  isLoadingWorkspace: boolean;
  role?: "secretary" | "admin";
  handleChangeWorkspace: (workspace: Workspace) => void;
  isLoadingTemplate: boolean;
  isWorkspacesError: boolean;
}

const WorkspaceContext = React.createContext<WorkspaceProps>(
  {} as WorkspaceProps
);

export const useWorkSpace = () => useContext(WorkspaceContext);

export const WorkspaceProvider = ({ children }: React.PropsWithChildren) => {
  const { currentUser, claims } = useAuth();

  const {
    data,
    isLoading: isLoadingWorkspace,
    isError: isWorkspacesError,
  } = useQuery([currentUser?.uid], browseWorkspaces, {
    enabled: Boolean(currentUser),
    onSuccess: (data) => {
      setCurrentWorkspace(data.data[0]);
    },
  });

  const workspacesData = data?.data;

  const [currentWorkspace, setCurrentWorkspace] = useState<Workspace | null>(
    null
  );

  const role = claims?.find(
    (workspace) => workspace.workspace_id === currentWorkspace?.workspace_id
  )?.role as "secretary" | "admin";

  const { data: templateData, isLoading: isLoadingTemplate } = useQuery(
    ["template", currentWorkspace?.template_id],
    () => readTemplate(currentWorkspace?.template_id!),
    {
      enabled: Boolean(currentWorkspace?.template_id) && Boolean(currentUser),
    }
  );

  useEffect(() => {
    if (!currentUser) {
      setCurrentWorkspace(null);
    }
  }, [currentUser]);

  const template = templateData?.data;

  const handleChangeWorkspace = (workspace: Workspace) =>
    setCurrentWorkspace(workspace);

  const value = {
    currentWorkspace,
    workspaces: workspacesData || [],
    handleChangeWorkspace,
    isLoadingWorkspace,
    isWorkspacesError,
    template,
    role,
    isLoadingTemplate,
  };

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
};
