import { ConfigProvider, theme } from "antd";
import { PropsWithChildren } from "react";
import { useDarkTheme } from "../../hooks";
import enUS from "antd/locale/en_US";
import frFr from "antd/locale/fr_FR";
import { useTranslation } from "react-i18next";

export const ThemeConfigProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { darkTheme } = useDarkTheme();
  const { i18n } = useTranslation();
  return (
    <ConfigProvider
      locale={i18n.resolvedLanguage === "en" ? enUS : frFr}
      theme={{
        token: {
          borderRadius: 8,
        },
        components: {
          Button: {
            borderRadius: 8,
          },
        },
        algorithm: darkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
      }}
    >
      {children}
    </ConfigProvider>
  );
};
