import { Typography } from "antd";
import styled from "styled-components";

interface Props {
  value?: string;
  copyable?: boolean;
  multiline?: boolean;
  onConfirm?: (value: string) => void;
}

export const FieldValueDisplay: React.FC<Props> = ({
  value,
  copyable,
  multiline,
  onConfirm,
}) => {
  const renderValue = multiline
    ? value
        ?.split("\n")
        .filter((v) => v)
        .map((value, key) => (
          <span key={key}>
            {value}
            <br />
          </span>
        ))
    : value;

  return (
    <StyledFieldValueDisplay
      editable={
        onConfirm
          ? {
              onChange: (value) => {
                onConfirm(value);
              },
            }
          : false
      }
      copyable={Boolean(value) && copyable}
      type={value ? undefined : "secondary"}
      ellipsis
    >
      {renderValue || (onConfirm ? "" : "N/A")}
    </StyledFieldValueDisplay>
  );
};

const StyledFieldValueDisplay = styled(Typography.Text)``;
