import { Layout, Menu, MenuProps } from "antd";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import React, { createRef } from "react";
import { Paths, dashboardItems } from "./router";
import { MobileDrawerProvider, useAuth, useWorkSpace } from "./contexts";
import { LoadingWorkspace, UserMenu } from "./components";
import { SiderLogo } from "./design";
import { useMobileView, useScreen } from "./hooks";

const { Content, Sider } = Layout;

export const Root: React.FC = () => {
  const { currentUser } = useAuth();
  const { isLoadingWorkspace } = useWorkSpace();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const contentRef = createRef<HTMLElement>();
  const { screenHeight, isMobile } = useScreen();
  const { isPWA } = useMobileView();

  const items: MenuProps["items"] = dashboardItems.map((path, index) => {
    return {
      key: path.path,
      icon: path.icon,
      label: path.name,
      title: "",
    };
  });

  if (!currentUser) {
    return <Navigate to={Paths.Login} replace state={{ path: pathname }} />;
  }

  if (isLoadingWorkspace) {
    return <LoadingWorkspace />;
  }

  return (
    <StyledLayout $height={`${screenHeight}px`}>
      {!isMobile && (
        <Sider theme="light" collapsedWidth={100} collapsed>
          <div>
            <SiderLogo />
            <Menu
              style={{ marginTop: 56 }}
              mode="vertical"
              selectedKeys={[`/${pathname.split("/")[1]}`]}
              items={items}
              onClick={(item) => navigate(item.key)}
            />
          </div>
          <UserMenu />
        </Sider>
      )}
      <StyledContent
        $height={isPWA ? "100vh" : `${screenHeight}px`} // Fixing bug on Safari not able to readjust screen height on Device rotation
        ref={contentRef}
        id="app-content"
      >
        <MobileDrawerProvider>
          <Outlet context={{ contentRef }} />
        </MobileDrawerProvider>
      </StyledContent>
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)<{ $height: string }>`
  .ant-layout-sider {
    min-height: ${({ $height }) => `calc(${$height} - 48px)`};
    margin: 24px 0px 24px 24px;
    border-radius: 24px;
    text-align: center;
    .ant-menu-vertical {
      border-right: none;
    }
  }
  .ant-layout-sider-children {
    padding: 36px 0px 48px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ant-layout-header {
    height: 56px;
    line-height: 56px;
    padding-inline: 16px;
  }
`;

const StyledContent = styled(Content)<{ $height: string }>`
  padding: 0px 24px 120px 24px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 24px);
  height: ${({ $height }) => $height};
  overflow: auto;
`;
