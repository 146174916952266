import styled from "styled-components";
import {
  deleteObject,
  listAll,
  ref,
  StorageReference,
} from "@firebase/storage";
import { storage } from "../../api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Button,
  Card,
  Col,
  message,
  Modal,
  Row,
  Skeleton,
  Typography,
} from "antd";
import { FilePreview } from "./FilePreview";
import { DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import { t } from "i18next";

interface Props {
  workspace_id?: string;
  patient_id?: string;
}

export const FilesList: React.FC<Props> = ({ patient_id, workspace_id }) => {
  // Create a reference under which you want to list
  const listRef = ref(storage, `patients/${workspace_id}/${patient_id}`);
  const [targetFile, setTargetFile] = useState<StorageReference>();

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ["files-list", patient_id, workspace_id],
    () => listAll(listRef)
  );

  const [messageApi, contextHolder] = message.useMessage();
  const [deleteModalOpen, setOpenDeleteModal] = useState(false);

  const { mutate: deleteFile, isLoading: isLoadingDeleting } = useMutation(
    (file: StorageReference) => deleteObject(file),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("files-list");
        setOpenDeleteModal(false);
        messageApi.open({
          type: "success",
          content: t("delete_success_message"),
        });
      },
    }
  );

  const files = data?.items;

  if (isLoading) {
    return (
      <StyledLoadingRow style={{ marginTop: 24 }} gutter={[16, 16]}>
        {Array.from({ length: 6 }).map((_, i) => {
          return (
            <Col span={8} key={i}>
              <Card
                title={
                  <Skeleton
                    active
                    loading
                    title={false}
                    paragraph={{ rows: 1 }}
                  />
                }
              >
                <Skeleton
                  active
                  loading
                  title={false}
                  paragraph={{ rows: 1 }}
                />
              </Card>
            </Col>
          );
        })}
      </StyledLoadingRow>
    );
  }

  return (
    <StyledFilesList>
      {contextHolder}
      <StyledDeleteModal
        destroyOnClose
        open={deleteModalOpen}
        title={
          <StyledDeleteModalTitle>
            <DeleteOutlined />
            <Typography.Title style={{ margin: 0 }} level={4}>
              {t("delete_modal_title", { entity: t("file") })}
            </Typography.Title>
          </StyledDeleteModalTitle>
        }
        onOk={() => deleteFile(targetFile!)}
        okText={t("delete")}
        cancelText={t("no")}
        onCancel={() => setOpenDeleteModal(false)}
        cancelButtonProps={{ size: "large" }}
        okButtonProps={{ loading: isLoadingDeleting, size: "large" }}
        okType="danger"
      >
        <Typography.Text style={{ marginBottom: 24 }}>
          {t("are_you_sure_delete", {
            name: targetFile?.name,
          })}
        </Typography.Text>
      </StyledDeleteModal>
      <Row gutter={[16, 16]}>
        {files?.map((file) => {
          return (
            <Col sm={24} xs={24} md={8} lg={6} key={file.fullPath}>
              <StyledFileCard
                actions={[]}
                title={
                  <Row
                    align="middle"
                    gutter={8}
                    wrap={false}
                    justify="space-between"
                  >
                    <Typography.Text
                      style={{ maxWidth: "80%", paddingLeft: 4 }}
                      ellipsis
                    >
                      {file.name}
                    </Typography.Text>
                    <Row>
                      <Col>
                        <Button
                          onClick={() => {
                            setOpenDeleteModal(true);
                            setTargetFile(file);
                          }}
                          icon={<DeleteOutlined />}
                        />
                      </Col>
                    </Row>
                  </Row>
                }
              >
                <FilePreview
                  isImage={file.name.split(".")[1] !== "pdf"}
                  file={file}
                />
              </StyledFileCard>
            </Col>
          );
        })}
      </Row>
    </StyledFilesList>
  );
};

const StyledFileCard = styled(Card)`
  border-radius: 0px !important;
  .ant-card-head {
    padding: 0px 8px;
  }
`;

const StyledDeleteModal = styled(Modal)`
  .ant-modal-body {
    margin-bottom: 24px;
  }
`;

const StyledLoadingRow = styled(Row)`
  .ant-skeleton-paragraph > li {
    width: 100% !important;
  }
`;

const StyledFilesList = styled.div`
  margin-top: 24px;
  .ant-card {
    :hover {
      cursor: pointer;
    }
  }
  .ant-card-body {
    text-align: center;
  }
`;

const StyledDeleteModalTitle = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;
