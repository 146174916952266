import { message, Modal } from "antd";
import styled from "styled-components";

import { isValidPatient } from "../../utils";
import { IdcardOutlined } from "@ant-design/icons";
import { PatientForm } from "./PatientForm";
import { PatientFormProvider, usePatientFormContext } from "../../contexts";
import { Patient } from "../../model";
import { useMutation, useQueryClient } from "react-query";
import { updatePatient as updatePatientApi } from "../../api";
import { useTranslation } from "react-i18next";
import { StyledModalTitle } from "./NewPatientModal";
import lodash from "lodash";

interface Props {
  visible: boolean;
  onClose: () => void;
  initialValues: Patient;
  onSave?: () => void;
}

export const EditPatientModal: React.FC<Props> = (props) => (
  <PatientFormProvider initialValues={{ ...props.initialValues } as any}>
    <EditPatientModalContent {...props} />
  </PatientFormProvider>
);

export const EditPatientModalContent: React.FC<Props> = ({
  visible,
  onClose,
  initialValues,
  onSave,
}) => {
  const { formValues, updateValues } = usePatientFormContext();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const [messageApi, contextHolder] = message.useMessage();

  const { mutate: updatePatient, isLoading } = useMutation(updatePatientApi, {
    onError: (e) => console.log(e),
    onSuccess: (e) => {
      queryClient.invalidateQueries(["patients"]);
      onClose();
      messageApi.open({
        type: "success",
        content: t("edit_success_message"),
      });
      onSave?.();
    },
  });

  const handleSaveClick = () => updatePatient(lodash.pickBy(formValues));

  return (
    <StyledEditPatientModal>
      {contextHolder}
      <StyledModal
        maskClosable={false}
        destroyOnClose
        cancelText="Annuler"
        okText="Sauvergarder"
        okButtonProps={{
          size: "large",
          disabled:
            isValidPatient(formValues) || !isDirty(formValues, initialValues),
          loading: isLoading,
        }}
        cancelButtonProps={{ size: "large" }}
        title={
          <StyledModalTitle level={3}>
            <IdcardOutlined />
            {initialValues.last_name} {initialValues.first_name}
          </StyledModalTitle>
        }
        open={visible}
        onOk={handleSaveClick}
        onCancel={() => {
          onClose();
          updateValues(initialValues);
        }}
      >
        <PatientForm />
      </StyledModal>
    </StyledEditPatientModal>
  );
};

const StyledEditPatientModal = styled.div``;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-bottom: 0px;
  }
  .ant-modal-footer {
    padding: 12px 16px;
    button {
      min-width: 110px;
      border-radius: 32px;
    }
  }
`;

const isDirty = (a: Patient, b: Patient) =>
  a.address?.trim() !== b.address?.trim() ||
  a.first_name?.trim() !== b.first_name?.trim() ||
  a.last_name?.trim() !== b.last_name?.trim() ||
  a.gender?.trim() !== b.gender?.trim() ||
  a.birthdate?.trim() !== b.birthdate?.trim() ||
  a.phone_number?.number?.trim() !== b.phone_number?.number?.trim() ||
  a.phone_number?.code?.trim() !== b.phone_number?.code?.trim() ||
  a.email !== b.email?.trim();
