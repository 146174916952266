import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { t } from "i18next";
import { ArrowLeftOutlined } from "@ant-design/icons";

interface Props {
  customPath?: string;
}

export const ReturnButton: React.FC<Props> = ({ customPath }) => {
  const navigate = useNavigate();
  const path = customPath || -1;

  return (
    <StyledReturnButton
      icon={<ArrowLeftOutlined />}
      type="link"
      size="large"
      onClick={() => navigate(path as any)}
    >
      {t("back")}
    </StyledReturnButton>
  );
};

const StyledReturnButton = styled(Button)`
  padding-left: 0px !important;
`;
