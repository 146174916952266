import React, { useContext, useState } from "react";
import { Patient } from "../model";
import { newPatient } from "../utils";

interface PatientFormContextProps {
  formValues: Patient;
  updateValues: (formValues: Patient) => void;
}

const PatientFormContext = React.createContext<PatientFormContextProps>(
  {} as PatientFormContextProps
);

export const usePatientFormContext = () => useContext(PatientFormContext);

export const PatientFormProvider = ({
  children,
  initialValues,
}: React.PropsWithChildren & { initialValues?: Patient }) => {
  const [formValues, setFormValues] = useState<Patient>(
    initialValues || newPatient()
  );

  const updateValues = (patient: Patient) => setFormValues(patient);

  const value: PatientFormContextProps = {
    formValues,
    updateValues,
  };

  return (
    <PatientFormContext.Provider value={value}>
      {children}
    </PatientFormContext.Provider>
  );
};
